<template>
	<div class="leftmenu" :class="{open : store.leftmenuhidden === false}">
		<div>
			<div class="logo-holder">
				<img src="@/assets/kevin_logo.png" />
			</div>
			<div class="lmenu-items">
				<router-link to="/dashboard" title="Dashboard" class="lmenu-item" :class="{active : $route.path === '/dashboard'}">
					<i class="mdi mdi-view-dashboard"></i><span>Dashboard</span>
				</router-link>
				<router-link to="/assets" title="List of saleable assets" class="lmenu-item" :class="{active : $route.path === '/assets'}">
					<i class="mdi mdi-domain"></i><span>Assets</span>
				</router-link>
				<router-link to="/calendar" title="Calendar view" class="lmenu-item" :class="{active : $route.path === '/calendar'}">
					<i class="mdi mdi-calendar"></i><span>Calendar</span>
				</router-link>
				<router-link v-if="store.user.isAdmin === 1" to="/users" title="User admin" class="lmenu-item" :class="{active : $route.path === '/users'}">
					<i class="mdi mdi-account-multiple"></i><span>Users</span>
				</router-link>
				<router-link to="/booking-parents" title="Manage & view form submissions" class="lmenu-item" :class="{active : $route.path === '/booking-parents'}">
					<i class="mdi mdi-sitemap"></i><span>Booking Groups</span>
				</router-link>
				<router-link to="/bookings" title="Manage & view bookings" class="lmenu-item" :class="{active : $route.path === '/bookings'}">
					<i class="mdi mdi-format-list-bulleted"></i><span>Booked Items</span>
				</router-link>
				<router-link to="/new-booking" title="Create a new booking request" class="lmenu-item" :class="{active : $route.path === '/new-booking'}">
					<i class="mdi mdi-send-outline"></i><span>New Booking</span>
				</router-link>
				<router-link to="/logout" title="Log out" class="lmenu-item">
					<i class="mdi mdi-logout"></i><span>Log Out</span>
				</router-link>
			</div>
		</div>
		<div class="transposers">
			<a @click='transpose' title='Toggle menu width'>
				<i v-if="store.leftmenuhidden === true" class='mdi mdi-arrow-collapse-right'></i>
				<i v-if="store.leftmenuhidden === false" class='mdi mdi-arrow-collapse-left'></i>
			</a>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
export default {
	name: "LeftMenu",
	components: {},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		p() {
			return this.$route
		},
		user() {
			return this.store.user;
		},
		leftmenuhidden() {
			return this.store.leftmenuhidden;
		},
	},
	methods: {
		transpose() {
			let newval = true;
			if(this.leftmenuhidden) newval = false;
			this.store.leftmenuhidden = newval;
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.leftmenu {
	width: 100px;
	padding: 55px 0;
	height: calc(100vh - 110px);
	position: fixed;
	top: 0;
	left: 0;
	background-color: var(--lightGrey);
	border-right: 1px solid var(--grey);
	z-index: 980;
	text-align: left;
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.leftmenu.open {
	width: 230px;
	padding: 55px 0;
}
.logo-holder {
	padding-bottom: 30px;
	text-align: center;
	width: 60px;
	height: 60px;
	margin: 0 auto 20px;
	transition: all 0.1s ease-in-out;
}
.leftmenu.open .logo-holder {
	width: 90px;
	height: 90px;
}
.logo-holder img {
	width: 100%;
	transition: all 0.1s ease-in-out;
}
.lmenu-items {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.lmenu-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: var(--blue);
	font-weight: 500;
	padding: 10px 5px;
	transition: all 0.3s ease-in-out;
}
.lmenu-item.active {
	background-color: var(--lightGreyHover);
}
.leftmenu.open .lmenu-item {
	justify-content: flex-start;
	padding-left: 35px;
	padding-right: 35px;
}
.lmenu-item:hover {
	color: var(--darkBlue);
	background-color: var(--lightGreyHover);
}
.lmenu-item i {
	padding: 0 10px;
	font-size: 22px;
}
.lmenu-item span {
	display: none;
	font-size: 15px;
}
.leftmenu.open .lmenu-item span {
	display: inline-block;
}
.transposers {
	margin-left: -11px;
	color: var(--blue);
	display: flex;
	justify-content: center;
	font-size: 22px;
}
@media screen and (max-width: 1200px) {
	.transposers {
		display: none;
	}
	.leftmenu.open {
		width: 90px;
		padding: 55px 5px;
	}
	.leftmenu.open .logo-holder {
		width: 60px;
		height: 60px;
	}
	.leftmenu.open .lmenu-item {
		justify-content: center;
	}
	.leftmenu.open .lmenu-item span {
		display: none;
	}
}
@media screen and (max-width: 840px) {
	.leftmenu {
		display: none;
	}
}
</style>