export default class AssetClass {
	id = 0
	name = ''
	assetTypeId = null
	assetSubtypeId = null
	stationId = 0
	max_simultaneous = 1
	description = null

	bookings = []
	status = null
	daterange = []
	weeksCommencingBookings = []
	clientNames = []

	prepareItemWeeks(assetOb, mybookings, daterange) {
		this.id = assetOb.id
		this.name = assetOb.name
		this.assetTypeId = assetOb.assetTypeId
		this.assetSubtypeId = assetOb.assetSubtypeId
		this.stationId = assetOb.stationId
		this.description = assetOb.description
		this.max_simultaneous = assetOb.max_simultaneous

		//bookings should be in order startdate ascending
		this.bookings = mybookings.sort((a,b) => (a.startdate > b.startdate) ? 1 : ((b.startdate > a.startdate) ? -1 : 0))
		this.daterange = daterange

		let weeksCommencingBookings = []
		let clientNames = []
		let counter = 0
		let weekdates = [] //will be {startdate: YYYY-MM-DD, enddate: YYYY-MM-DD} object array
		let myweek = {}
		// let firstweekstatus = ''
		for(let dt of this.daterange) {
			if(counter === 0) { //this is a monday
				myweek.startdate = dt
			}
			else if(counter === 6) { //this is a sunday
				myweek.enddate = dt
				weekdates.push(myweek)
				counter = 0 //reset
				myweek = {}
				continue
			}
			counter++
		}
		//loop through weeks
		let wkcount = 0
		for(let wk of weekdates) {
			let bobs = this.bookings.filter(item => item.startdate <= wk.enddate && item.enddate >= wk.startdate)
			let soldcountForWeek = 0
			let pendcountForWeek = 0
			for(let bob of bobs) {
				if (!clientNames.includes(bob.clientName)) clientNames.push(bob.clientName)
				// if(wkcount === 0) {
				// 	if (bob.status === 'sold') firstweekstatus = 'sold'
				// 	else if(bob.status === 'pending' && firstweekstatus !== 'sold') firstweekstatus = 'pending'
				// }
				if(bob.status === 'sold') soldcountForWeek++
				else if(bob.status === 'pending') pendcountForWeek++
			}

			let weekSoldStatus = null
			if(soldcountForWeek >= this.max_simultaneous) weekSoldStatus = 'sold'
			else if(pendcountForWeek > 0) weekSoldStatus = 'pending'
			let ob = {
				dt: wk.startdate,
				dtend: wk.enddate,
				bookings: bobs,
				weekSoldStatus: weekSoldStatus,
				nSold: soldcountForWeek,
				nPending: pendcountForWeek,
				maxAvail: this.max_simultaneous
			}

			weeksCommencingBookings.push(ob)
			if(wkcount === 0) {
				if(soldcountForWeek >= this.max_simultaneous) this.status = 'Sold'
				else if(pendcountForWeek > 0) this.status ='Pending'
				else this.status = 'Available'
			}
			wkcount++
		}
		this.weeksCommencingBookings = weeksCommencingBookings
		this.clientNames = clientNames
	}

}