<template>
	<div class="book-item" :class="booking.status">
		<div class="vertical-bar"></div>
		<div class="book-item-detail">
			<h6 @click="openModal">{{booking.clientName}}</h6>
			<p class="rep">{{getRepNameFromId(booking.repId)}}</p>
			<p class="status" :class="booking.status">{{ucfirst(booking.status)}}</p>
			<div class="date">
				{{getNiceDate(booking.startdate)}} - {{getNiceDate(booking.enddate)}}
			</div>
		</div>
	</div>

	<BookingEditPopup v-if="editBookingPopupShown" :bookingobin="booking" v-on:close="editBookingPopupShown = false" />

</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import BookingEditPopup from "@/components/BookingEditPopup";

export default {
	name: "BookingTileWidget",
	components: {
		BookingEditPopup

	},
	props: {
		booking: Object
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			editBookingPopupShown: false,
		}
	},
	computed: {

	},
	methods: {
		openModal() {
			this.editBookingPopupShown = true
		},
	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.book-item {
	display: flex;
	color: #1E3152;
	box-sizing: border-box;
	width: 100%;
	background-color: #F1F2F4;
	border: 4px solid #F1F2F4;
	padding: 25px 30px;
	margin: 0 10px 20px;
}

.vertical-bar {
	width: 10px;
	height: 100%;
	margin-right: 25px;
}
.sold .vertical-bar {
	background-color: var(--sold);
}
.pending .vertical-bar {
	background-color: var(--pending);
}
.available .vertical-bar {
	background-color: var(--available);
}
.book-item h6 {
	font-size: 20px;
	font-weight: 700;
	cursor: pointer;
}
.book-item h6:hover {
	text-decoration: underline;
}
.book-item .date {
	font-size: 17px;
	font-weight: 500;
	margin: 10px 0 0;
}
.book-available {
	background-color: #E0F6F1;
	border: 4px solid #E0F6F1;
}
.book-available .vertical-bar {
	background-color: #00B289;
}
.book-available h6 {
	color: #00B289;
}
.rep {
	font-size: 16px;
	margin: 15px 0 10px 0;
}
.status {
	margin: 10px 0;
	font-size: 14px;
}
</style>