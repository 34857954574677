import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import LoginPage from "@/components/pageviews/LoginPage";
import LogoutPage from "@/components/pageviews/LogoutPage";
import DashboardPage from "@/components/pageviews/DashboardPage";
import AssetsPage from "@/components/pageviews/AssetsPage";
import AssetDetailPage from "@/components/pageviews/AssetDetailPage";
import CalendarPage from "@/components/pageviews/CalendarPage";
import UsersPage from "@/components/pageviews/UsersPage";
import NewBookingFormPage from "@/components/pageviews/NewBookingFormPage";
import BookingsPage from "@/components/pageviews/BookingsPage";
import BookingParentsPage from "@/components/pageviews/BookingParentsPage";

//routing
const routes = [
	{path: '/', component: LoginPage},
	{path: '/logout', component: LogoutPage},
	{path: '/dashboard', component: DashboardPage}, //landing page - replacing old / home/dash page (future incl almost dropdead list, expiring sponsors, reminders re credits needed)
	{path: '/assets', component: AssetsPage}, //list of saleable items replaces old /tracker & admin/entries pages - only admins can edit obviously (in modal)
	{path: '/assets/:assetId', component: AssetDetailPage}, //single asset with info (viewable by all, editable in modal by admins)
	{path: '/calendar', component: CalendarPage}, //replaces old /calendar page
	{path: '/users', component: UsersPage}, //admin only user list (and edit/add in modal incl station permission assignments)
	{path: '/new-booking', component: NewBookingFormPage}, //form that reps fill out
	{path: '/bookings', component: BookingsPage}, //list of bookings (one row per client), admins can change details in modal, reps can only view their own incl submissions awaiting admin approval
	{path: '/booking-parents', component: BookingParentsPage}, //list of booking parents (one row per form submission), admins can change details in modal, reps can only view their own incl submissions awaiting admin approval

]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const app = createApp(App)
app.use(router);
app.mount('#app')
