<template>
	<div class="logline">
		<div style="display: inline-block; padding-right: 20px;">{{logitem.tstamp}}</div>
		<div style="display: inline-block; padding-right: 20px;">{{getRepNameFromId(logitem.uid)}}</div>
		<div style="display: inline-block;">{{logitem.action}}</div>
	</div>

</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";

export default {
	name: "LoggingItem",
	components: {

	},
	props: {
		logitem: Object
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>