<template>
	<div>
		<LeftMenu></LeftMenu>
		<div class="wrapper" :class="{open : store.leftmenuhidden === false}">
			<div class="wrapper-inside">
				<div class="title-section">
					<h1>Calendar</h1>
				</div>
				<CalendarView></CalendarView>
			</div>
		</div>
		<BottomMobileMenu></BottomMobileMenu>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import LeftMenu from "@/components/LeftMenu";
import CalendarView from "@/components/CalendarView";
import BottomMobileMenu from "@/components/BottomMobileMenu";

export default {
	name: "CalendarPage",
	components: {BottomMobileMenu, CalendarView, LeftMenu},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>