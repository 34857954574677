<template>
	<BlanketOverlayWithPopup v-if="assetobin" :title="popupTitle" width="600" v-on:close="closeCancel">
		<template v-slot:main>
            <div class="form-input-group">
                <p class="form-headline">Name</p>
                <input v-model="assetEditOb.name" placeholder="Name" />
            </div>
            <div class="flex" style="justify-content: space-between;">
                <div class="half">
                    <div class="form-input-group">
                        <p class="form-headline">Type</p>
                        <select v-model="assetEditOb.assetTypeId">
                            <option v-for="type in store.assetTypes" :value="type.id" :key="type.id" :selected="type.id === assetEditOb.assetTypeId">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="half">
                    <div class="form-input-group">
                        <p class="form-headline">Sub Type</p>
                        <select v-model="assetEditOb.assetSubtypeId">
                            <option v-for="type in store.assetSubtypes.filter((item) => item.parentId === assetEditOb.assetTypeId)" :value="type.id" :key="type.id" :selected="type.id === assetEditOb.assetSubtypeId">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
			<div class="flex" style="justify-content: space-between;">
				<div class="half">
					<div class="form-input-group">
						<p class="form-headline">Station</p>
						<select v-model="assetEditOb.stationId">
							<option v-for="type in store.stations" :value="type.id" :key="type.id" :selected="type.id === assetEditOb.assetSubtypeId">{{ type.name }}</option>
						</select>
					</div>
				</div>
				<div class="half">
					<div class="form-input-group">
						<p class="form-headline">Max Simultaneous Bookings</p>
						<input type="number" v-model="assetEditOb.max_simultaneous" />
					</div>
				</div>
			</div>
            <div class="form-input-group">
                <p class="form-headline">Description</p>
                <textarea rows="5" v-model="assetEditOb.description"></textarea>
            </div>
			<div>
				<button type="button" class="option-button delete-button" @click="deleteAsset">Delete Asset</button>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button cancel-button" @click="closeCancel">
				<span v-if="store.user.isAdmin === 1">Cancel</span>
				<span v-else>Close</span>
			</button>
			<button v-if="store.user.isAdmin === 1" type="button" class="option-button" @click="saveAssetInfo">Save Info</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "AssetEditPopup",
	components: {
		BlanketOverlayWithPopup,
	},
	mixins: [globalMixin],
	props: {
		assetobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

            assetEditOb: {},

		}
	},
	computed: {
		popupTitle() {
			if(this.store.user.isAdmin === 1) return 'Asset Editor'
			else return 'Asset Detail'
		}
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveAssetInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true;
				this.isFetching = true;
				let targ = "/post/save-asset-details.php";
				let myob = {};
				let fields = ['assetSubtypeId','assetTypeId','description','id','max_simultaneous','name','stationId']
				for(let pr in this.assetEditOb) {
					if(fields.includes(pr)) myob[pr] = this.assetEditOb[pr]
				}
				let sendob = {assetob: myob};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data if needed
					if(ret.assets) {
						this.store.assets = ret.assets
						this.prepareAssetItems()
					}
					this.isFetching = false;
					this.store.showLoader = false;
					this.$emit('close');
				}.bind(this));
			}
		},
		deleteAsset() {
			let c = window.confirm("Are you sure you want to delete this asset?");
			if(c !== false) {
				this.store.showLoader = true;
				this.isFetching = true;
				let targ = "/post/delete-asset.php";
				let myob = {};
				let fields = ['id']
				for(let pr in this.assetEditOb) {
					if(fields.includes(pr)) myob[pr] = this.assetEditOb[pr]
				}
				let sendob = {assetob: myob};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data if needed
					if(ret.assets) {
						this.store.assets = ret.assets
						this.prepareAssetItems()
					}
					this.isFetching = false;
					this.store.showLoader = false;
					this.$emit('close');
				}.bind(this));
			}
		},
		populateContent() {
			if(this.assetobin) {
				for(let pr in this.assetobin) {
					this.assetEditOb[pr] = this.assetobin[pr];
				}
			}
		},
	},
	watch: {
        assetobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	}
}
</script>

<style scoped>
@media screen and (max-width: 700px) {

}
</style>