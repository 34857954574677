import { reactive } from 'vue'

let isDevServer = false;
let urlroot = 'https://askkevin.com.au/'; //api call url
if (window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
	if(window.location.origin.indexOf('8080')) { //alan
		urlroot = 'http://ace-kevin.test/';
	}
	else {
		urlroot = 'http://localhost/ask-kevin/'
	}
}

export const store = reactive({
	urlroot: urlroot,
	isDevServer: isDevServer,

	sessionOk: false,
	fetching: false,
	user: {
		id: 0,
		email: '',
		isAdmin: 0,
		fname: '',
		lname: '',
		marketId: null,
		stationPerms: [],
		status: '',
	},
	kevsesstoken: '',

	kalert: {
		shown: false,
		message: null,
		type: 'danger',
	},

	leftmenuhidden: false,
	showLoader: false,
	forceMenusClose: 0,
	filters: {
		text: '',
		stationId: [],
		assetTypeId: [],
		assetSubtypeId: [],
		status: [],
		rep_names: [],
		clientNames: []
	},
	tempBookingGroupFilter: null,

	// global arrays of objects
	initialObjectsFetched: false,
	users: [],
	markets: [],
	stations: [],
	assetTypes: [],
	assetSubtypes: [],
	assets: [],
	bookings: [],
	booking_parents: [],

	assetsExtended: [],
	// end global arrays
})