<template>
	<div class="bgpage" style="padding-top: 50px; height: calc(100vh - 50px);">

		<div v-cloak>

			<div id="form-wrapper">
				<div>
					<div id="logo-holder">
						<a href="https://askkevin.com.au">
							<img src="@/assets/kevin_logo.png" alt="Logo" />
						</a>
					</div>

					<div>
						<div v-if="store.isDevServer">
							<div class="login-form-input-group">
								<input type='email' v-model="email" placeholder="Email Address" />
								<div class="login-form-input-fail" :class="{shown : emailState === false}">Please enter a valid email address.</div>
							</div>
							<div v-if="showingForgetPwd === false" class="login-form-input-group" style="margin-bottom: 10px;">
								<input type='password' v-model="pass" placeholder="Password" />
							</div>
							<button type="button" v-if="showingForgetPwd === false" class="login-button" v-on:click="submitForm" v-bind:class="{'button-is-working':(form1working === true)}">
								<span>Login</span>
							</button>
						</div>

						<a href='/sso-land.php?login=1' class="login-button">
							<span>Ace Users Login</span>
						</a>

						<div>
							<div class="ms-login-btn" v-on:click="doMSAdLogin">
								<div><img src="@/assets/microsoft-logo.png" /></div>
								<div><span>Sign in with Microsoft account (non-Ace)</span></div>
							</div>
						</div>

					</div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import * as msal from "@azure/msal-browser"; // MS login

export default {
	name: 'LoginPage',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			headerNotes: "",
			requestedLink: "", //for use after successful login

			showingForgetPwd: false,

			email: '',
			pass: '',

			checksTriggered: false,
			form1working: false,

			doingMSLogin: false,
			accessToken: null,
			adEmail: null,
		}
	},
	computed: {
		emailState() {
			if(this.checksTriggered === false && this.email.length >= 0 && /.+@.+\..+/.test(this.email) === false) {
				return null;
			}
			else if(this.email.length >= 0 && /.+@.+\..+/.test(this.email)) {
				return true;
			}
			return false;
		},
	},
	methods: {
		submitForm() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				let checkitems = [this.emailState]; //, this.passState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				let self = this;
				axios.post(
					"/post/do-login.php",
					JSON.stringify({
						email: this.email,
						pass: this.pass
					})
				).then(function(response) {
					let ret = response.data; //response.data is returned info
					if(ret.error) {
						self.form1working = false
						self.showKalertError(ret.error)
					}
					else if(ret.kevsesstoken) {
						self.finishSuccessfulLogin(ret)
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		doMSAdLogin() {
			if(!this.doingMSLogin) {
				let msalConfig = {
					auth: {
						clientId: '01a76df6-59f0-4101-b555-3a9bfefa78cf',
						authority: "https://login.microsoftonline.com/common/",
						redirectUri: 'https://askkevin.com.au/sso-callback-ms.php',
					}
				};
				this.doingMSLogin = true
				let self = this;
				const msalInstance = new msal.PublicClientApplication(msalConfig);
				msalInstance.acquireTokenPopup({
					scopes: ["User.Read"] // shorthand for https://graph.microsoft.com/User.Read
				}).then((response) => {
					if(response.accessToken) {
						self.accessToken = response.accessToken;
					}
					if(response.account && response.account.username) {
						self.adEmail = response.account.username;
					}
					self.finishMSLogin()
				}).catch((error) => {
					console.log(error); // handle errors
				});
			}
		},
		finishMSLogin() { //check from our server via MS Graph that person is who they say they are
			if(this.doingMSLogin === true && this.accessToken && this.adEmail) {
				let self = this
				axios.post(
					"/post/fin-microsoft-login.php",
					JSON.stringify({
						accessToken: this.accessToken,
						email: this.adEmail
					})
				).then(function(response) {
					let ret = response.data //response.data is returned info
					self.doingMSLogin = false
					if(ret.kevsesstoken) { //if this comes back we have logged in successfully
						self.finishSuccessfulLogin(ret)
					}
					else if(ret.error) {
						self.form1working = false
						self.showKalertError(ret.error)
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		finishSuccessfulLogin(ret) {
			this.store.kevsesstoken = ret.kevsesstoken
			this.store.user = ret.user
			this.store.sessionOk = true
			window.sessionStorage.setItem('kevSessToken', ret.kevsesstoken);
			window.sessionStorage.setItem('kevUserId', ret.user.id);
			this.$router.push("/dashboard");
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "ACE Radio - Kevin";
	},
	created() {
		//handle incoming GET parameters
		// let urlparams = this.$route.query;
		// if(urlparams.activated !== undefined && parseInt(urlparams.activated) === 1) {
		// 	this.headerNotes = "Thanks for activating your account - you can now log in!";
		// }
		// if(urlparams.passreset !== undefined && parseInt(urlparams.passreset) === 1) {
		// 	this.headerNotes = "Your password has been changed - you can now log in!";
		// }
	},
}
</script>

<style scoped>
[v-cloak] {
	display: none;
}
.bgpage {
	background-color: var(--lightGrey);
}
#logo-holder {
	text-align: center;
	margin-bottom: 30px;
}
#logo-holder img {
	height: 100px;
}
#form-wrapper {
	margin: 100px auto;
	padding: 60px;
	width: 300px;
}
#form-wrapper input {
	margin-bottom: 10px;
	border: 1px solid #CCC;
	border-radius: 6px;
	padding: 10px 5px;
	font-size: 18px;
	width: calc(100% - 16px);
}
.login-form-input-group {
	margin-bottom: 20px;
	border-radius: 6px;
	font-size: 16px;
}
.login-form-input-fail {
	color: #F00;
	font-size: 12px;
	text-align: left;
	display: none;
}
.login-form-input-fail.shown {
	display: block;
}
.login-button {
	margin-bottom: 20px;
	border-radius: 6px;
	background: var(--blue);
	padding: 10px 10px;
	color: #fff;
	font-size: 16px;
	border: none;
	transition: ease-in-out 0.5s;
	width: 100%;
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login-button:hover {
	background: var(--darkBlue);
}
.ms-login-btn {
	border: 1px solid #dadce0;
	padding: 8px;
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 250px;
	margin: 0 auto 18px;
	border-radius: 4px;
	cursor: pointer;
}
.ms-login-btn img {
	margin-right: 8px;
}
.ms-login-btn:hover {
	border: 1px solid #d2e3fc;
}
.ms-login-btn .mdi-at {
	font-size: 20px;
	color: var(--darkBlue);
	margin-right: 8px;
}
.text-link {
	cursor: pointer;
	width: fit-content;
	padding-bottom: 1px;
	margin: 0 auto;
	border-bottom: 1px dotted transparent;
	transition: ease-in-out 0.5s;
}
.text-link:hover {
	border-bottom: 1px dotted #666;
}
</style>
