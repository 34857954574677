<template>
	<div class="bottom-menu">
		<router-link to="/dashboard" title="Dashboard" class="lmenu-item">
			<i class="mdi mdi-view-dashboard"></i>
		</router-link>
		<router-link to="/assets" title="List of saleable assets" class="lmenu-item">
			<i class="mdi mdi-domain"></i>
		</router-link>
		<router-link to="/calendar" title="Calendar view" class="lmenu-item">
			<i class="mdi mdi-calendar"></i>
		</router-link>
		<router-link v-if="store.user.isAdmin === 1" to="/users" title="User admin" class="lmenu-item">
			<i class="mdi mdi-account-multiple"></i>
		</router-link>
		<router-link to="/bookings" title="Manage & view bookings" class="lmenu-item">
			<i class="mdi mdi-format-list-bulleted"></i>
		</router-link>
		<router-link to="/new-booking" title="Create a new booking request" class="lmenu-item">
			<i class="mdi mdi-send-outline"></i>
		</router-link>
	</div>
</template>

<script>
import {store} from '@/store.js'
export default {
	name: "BottomMobileMenu",
	components: {},
	props: {},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.bottom-menu {
	display: none;
}

@media screen and (max-width: 840px) {
	.bottom-menu {
		display: flex;
		height: 80px;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: var(--lightGrey);
		border-top: 1px solid var(--grey);
		z-index: 980;
		text-align: left;
		transition: all 0.2s ease-in-out;
		justify-content: space-between;
		align-items: center;
	}
	.lmenu-item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		color: var(--blue);
		font-weight: 500;
		padding: 10px 0;
	}
	.leftmenu.open .lmenu-item {
		justify-content: flex-start;
	}
	.lmenu-item:hover {
		color: var(--darkBlue);
	}
	.lmenu-item i {
		padding: 0 15px;
		font-size: 24px;
	}
}

</style>