<template>
	<div>
		<LeftMenu></LeftMenu>
		<div class="wrapper" :class="{open : store.leftmenuhidden === false}">
			<div class="wrapper-inside">
				<div class="title-section">
					<h1>Dashboard</h1>
				</div>

				<div>
					<p style="font-weight: bold; font-size: 22px;">Status</p>
					<div class="maintiles">
						<div class="dashtile available" @click="goAssets('status','avail')">
							<p class="counttext">{{availCount}}</p>
							<p class="below-text">Available</p>
						</div>
						<div class="dashtile pending" @click="goBookingParents">
							<p class="counttext">{{pendCount}}</p>
							<p class="below-text">Pending</p>
						</div>
						<div class="dashtile sold" @click="goAssets('status','sold')">
							<p class="counttext">{{soldCount}}</p>
							<p class="below-text">Sold</p>
						</div>
					</div>
				</div>

				<div style="padding-top: 30px;">
					<p style="font-weight: bold; font-size: 22px; margin-bottom: 40px;">Stations</p>
					<div style="display: flex; flex-wrap: wrap; gap: 40px; justify-content: flex-start; ">
						<div v-for="st in stationAvails" :key="st.name" class="dashtile stationdashtile available" @click="goAssets('station',st.id)">
							<p class="counttext">{{st.name}}</p>
							<p class="below-text">{{st.avail}} Available</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<BottomMobileMenu></BottomMobileMenu>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import LeftMenu from "@/components/LeftMenu";
import BottomMobileMenu from "@/components/BottomMobileMenu";

export default {
	name: "DashboardPage",
	components: {BottomMobileMenu, LeftMenu},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		availCount() {
			return this.store.assetsExtended.filter(item => item.status === 'Available').length
		},
		pendCount() {
			return this.store.booking_parents.filter(item => item.status === 'pending').length
		},
		soldCount() {
			return this.store.assetsExtended.filter(item => item.status === 'Sold').length
		},
		stationAvails() {
			let s = []
			for(let st of this.store.stations) {
				let avails = this.store.assetsExtended.filter(item => item.status === 'Available' && item.stationId === st.id).length
				s.push({id: st.id, name: st.name, avail: avails})
			}
			return s
		},
	},
	methods: {
		goAssets(type, inf) {
			let f = {
					text: '',
					stationId: [],
					assetTypeId: [],
					assetSubtypeId: [],
					status: []
				};
			if(type === 'status') {
				if (inf === 'avail') f.status = ['Available']
				else if (inf === 'pend') f.status = ['Pending']
				else if (inf === 'sold') f.status = ['Sold']
			}
			else if(type === 'station') {
				f.stationId = [inf]
			}
			this.store.filters = f
			this.$router.push('/assets')
		},
		goBookingParents() {
			this.store.tempBookingGroupFilter = 'pending'
			this.$router.push('/booking-parents')
		},
	},
	watch: {

	},
	mounted() {
		if(!this.store.initialObjectsFetched) this.getInitialObjects()
	},
	created() {

	},
}
</script>

<style scoped>
.maintiles {
	display: flex;
	justify-content: space-between;
	margin: 40px 0;
}
.dashtile {
	width: 30%;
	padding: 60px 0;
	text-align: center;
	cursor: pointer;
}
.dashtile.stationdashtile {
	width: 300px;
	flex-grow: 1;
}
.dashtile.available {
	background-color: var(--available-light);
}
.dashtile.pending {
	background-color: var(--pending-light);
}
.dashtile.sold {
	background-color: var(--sold-light);
}
.counttext {
	font-size: 65px;
	font-weight: 700;
	margin-bottom: 15px;
}
.stationdashtile .counttext {
	font-size: 48px;
}
.below-text {
	font-size: 19px;
	font-weight: 500;
}
.pending {
	color: var(--pending);
}
.sold {
	color: var(--sold);
}
.available {
	color:  var(--available);
}

@media screen and (max-width: 840px) {
	.maintiles {
		display: flex;
		flex-wrap: wrap;
		margin: 40px 0;
	}
	.dashtile {
		width: 100%;
		margin-bottom: 40px;
	}
}
</style>