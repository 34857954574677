<template>

	<GlobalFilter></GlobalFilter>

	<div v-if="firstItem" class="calendar-hold">
		<div class="calendar-left">
			<div class="calendar-top">

			</div>
			<div class="calendar-main">
				<div v-for="as in rowsForPage" :key="as.id" class="cal-row-l">
					<router-link :to="'/assets/'+as.id">
						{{as.name}}
					</router-link>
				</div>
			</div>
		</div>
		<div class="calendar-right">
			<div class="calendar-right-inner">
				<div class="calendar-top">
					<div class="cal-week" v-for="(dob, index) in firstItem.weeksCommencingBookings" :key="index">
						{{getNiceDate(dob.dt, false)}}
					</div>
				</div>
				<div class="calendar-main">
					<div v-for="as in rowsForPage" :key="as.id" class="cal-row-r">
						<CalendarWeek v-for="(wkob, index) in as.weeksCommencingBookings" :key="index" :weekitem="wkob" :maxsim="as.max_simultaneous"></CalendarWeek>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div style="margin: 10px 0; display: flex; align-items: center;">
		<ALTablePaginator
				:paginationpage="paginationPage"
				:paginatedrowscount="paginatedRows.length"
				v-on:nextpage="nextpage" v-on:prevpage="prevpage"
				v-on:setpage="paginationPage = $event"
		></ALTablePaginator>
	</div>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import CalendarWeek from "@/components/CalendarWeek";
import ALTablePaginator from "@/components/ALTablePaginator";
import GlobalFilter from "@/components/GlobalFilter";

export default {
	name: "CalendarView",
	components: {
		GlobalFilter,
		ALTablePaginator,
		CalendarWeek,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
		}
	},
	computed: {
		//table stuff
		rows() {
            let rows = this.tblPrepareFilteredRows(this.store.assetsExtended, '', this.sortColName, this.sortColOrder)
            if( Object.keys(this.store.filters).length ) {
                for (let key in this.store.filters) {
                    // eslint-disable-next-line no-prototype-builtins
                    if( this.store.filters.hasOwnProperty(key) && this.store.filters[key].length ) {
                        rows = rows.filter((item) => {
                            if (key === 'clientNames' && item.clientNames.length && item.clientNames.every((element) => this.store.filters[key].includes(element) ) ) {
                                return item
                            } else if( key === 'rep_names' && item.bookings.length && item.bookings.every((booking) => this.store.filters[key].find( rep => rep.id === booking.repId )) ) {
                                return item
                            } else if( key !== 'text' ) {
                                // eslint-disable-next-line no-prototype-builtins
                                if (this.store.filters[key].includes(item[key])) {
                                    return item
                                }
                            } else {
                                if( item.name.toLowerCase().includes(this.store.filters[key].toLowerCase()) ) {
                                    return item
                                }
                            }
                        })
                    }
                }
            }

            return rows
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
		firstItem() {
			return this.store.assetsExtended[0]
		},
	},
	methods: {
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		if(!this.store.initialObjectsFetched) this.getInitialObjects()
	},
	created() {

	},
}
</script>

<style scoped>
.calendar-hold {
	width: 100%;
	position: relative;
	display: flex;
	height: auto;
	margin: 50px 0 20px;
}
.calendar-left {
	width: 400px;
	position: relative;
	height: auto;
}
.calendar-right {
	position: relative;
	width: calc(100% - 400px);
	overflow-x: auto;
	overflow-y: hidden;
	height: 100%;
}
.calendar-top {
    background-color: #E4E6EA;
	height: 55px;
	position: static;
}
.calendar-right .calendar-top {
	border-bottom: 1px solid #fff;
}
.calendar-left .calendar-top {

}
.calendar-left .calendar-main {
	top: 30px;
}
.cal-row-l {
	height: 30px;
	border: 1px solid #fff;
	display: flex;
	align-items: center;
    background-color: #F1F2F4;
	width: calc(100% - 50px);
    padding: 10px 25px;
}
.cal-row-l a {
    color: #1E3152;
	font-size: 16px;
}
.cal-row-r {
	height: 50px;
	border: 1px solid #fff;
	overflow: hidden;
}
.calendar-right-inner {
	width: 4500px;
}
.cal-week {
	width: 104px;
	height: 100%;
	top: 0;
	position: relative;
	border-left: 1px solid #fff;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: #1E3152;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	overflow: hidden;
}
.calendar-main .cal-week {
	background-color: var(--available-light);
}
.cal-week.pending {
	background-color: var(--pending);
}
.cal-week.sold {
    background-color: var(--sold);
}
.cal-week.oversold {
	background-color: var(--red);
}

@media screen and (max-width: 1000px) {
	.calendar-left {
		width: 200px;
	}
	.cal-row-l a {
		font-size: 13px;
	}
	.cal-week {
		font-size: 13px;
	}
	.calendar-right {
		width: calc(100% - 200px);
	}
}
</style>