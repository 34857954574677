import axios from "axios";
import AssetClass from "@/classes/AssetClass";

export const globalMixin = {
	methods: {
		showKalert(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = ob.type;
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertError(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'danger';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertSuccess(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'success';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		closeAllMenus() {
			this.store.forceMenusClose++;
		},
		simplePost(targ, sendob, alertOnError = true) {
			sendob.uid = this.store.user.id;
			sendob.kevsesstoken = this.store.kevsesstoken;
			return axios.post(targ, JSON.stringify(sendob)).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (alertOnError === true && ret.error) {
					this.showKalertError(ret.error);
				}
				return ret;
			}.bind(this)).catch(function (error) {
				console.log(error);
			});
		},
		clearLoginCredsAndSession() {
			let sendob = {uid: this.store.user.id, kevsesstoken: this.store.kevsesstoken};
			let self = this;
			axios.post("/post/do-logout.php", JSON.stringify(sendob)).then(function () {
				window.sessionStorage.removeItem('kevSessToken');
				window.sessionStorage.removeItem('kevUserId');
				self.store.user = {
					id: 0,
					email: '',
				};
				self.store.kevsesstoken = null;
				window.location = '/';
			}.bind(this)).catch(function (error) {
				console.log(error);
			});
		},
		numdisplay: function (num, maxfracdigits = 0) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: maxfracdigits});
		},
		convertZero(val, replacewith = '') {
			if(!isNaN(val) && val === 0) return replacewith
			return val
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		getMonthName(monthint) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[parseInt(monthint-1)]
		},
		getUniquesByKey(arr, keyname) {
			let temparr = []
			for(let s of arr) {
				if(!temparr.includes(s[keyname])) {
					temparr.push(s[keyname])
				}
			}
			return temparr;
		},
		countUniqueByValsByKey(arr, keyname) {
			let temparr = []
			for(let s of arr) {
				temparr.push(s[keyname])
			}			return new Set(temparr).size
		},
		sumByKey(arr, keyname) {
			let val = 0
			for(let s of arr) {
				if(s[keyname] && !isNaN(s[keyname])) {
					val = val + s[keyname]
				}
			}
			return val
		},
		dateDataByKey(arr, keyname) {
			let oldestDate = null
			let newestDate = null
			let existDates = [] //the dates that exist in the array (realising that some dates may not exist)
			let allDatesInRange = [] //create every date between oldest and newest date (for charting where missing vals are a problem)
			for(let s of arr) {
				if(!existDates.includes(s[keyname])) existDates.push(s[keyname])
			}
			existDates.sort()
			oldestDate = existDates[0]
			let oldestDateOb = this.createDateOb(oldestDate)
			newestDate = existDates[existDates.length-1]
			let newestDateOb = this.createDateOb(newestDate)
			allDatesInRange = this.createDateRangeBetween(oldestDate, newestDate)
			return {
				oldestDate: oldestDate,
				oldestDateOb: oldestDateOb,
				newestDate: newestDate,
				newestDateOb: newestDateOb,
				existDates: existDates,
				allDatesInRange: allDatesInRange,
			}
		},
		createDateOb(datestr) {
			let t = datestr.split("-")
			return {y: parseInt(t[0]), m: parseInt(t[1]), d: parseInt(t[2])}
		},
		createDateRangeBetween(oldestDate, newestDate) {
			let r = []
			let oldob = this.createDateOb(oldestDate)
			let newob = this.createDateOb(newestDate)
			let daysInMonth = null
			while(oldob.y <= newob.y) {
				if(oldob.y === newob.y) { //final year of checking - make sure months are lower or equal
					while(oldob.m <= newob.m) {
						daysInMonth = this.daysInMonth(oldob.m, oldob.y)
						if(oldob.m === newob.m) { //final month in final year
							while(oldob.d <= newob.d) {
								r.push(oldob.y+"-"+this.ldzero(oldob.m)+"-"+this.ldzero(oldob.d))
								oldob.d++
							}
						}
						else { //prior months in final year
							while(oldob.d <= daysInMonth) {
								r.push(oldob.y+"-"+this.ldzero(oldob.m)+"-"+this.ldzero(oldob.d))
								oldob.d++
							}
						}
						oldob.m++
						oldob.d = 1
					}
				}
				else { //previous years - do all months
					while(oldob.m <= 12) {
						daysInMonth = this.daysInMonth(oldob.m, oldob.y)
						while(oldob.d <= daysInMonth) {
							r.push(oldob.y+"-"+this.ldzero(oldob.m)+"-"+this.ldzero(oldob.d))
							oldob.d++
						}
						oldob.m++
						oldob.d = 1
					}
				}
				oldob.y++
				oldob.m = 1
			}
			return r;
		},
		ldzero(intval) {
			if(intval < 10) return '0'+intval.toString()
			return intval.toString()
		},
		daysInMonth(mth, yr) {
			mth = parseInt(mth)
			yr = parseInt(yr)
			if(mth === 1) return 31
			if(mth === 2) {
				if(parseInt(yr/4) === yr/4) return 29
				return 28
			}
			if(mth === 3) return 31
			if(mth === 4) return 30
			if(mth === 5) return 31
			if(mth === 6) return 30
			if(mth === 7) return 31
			if(mth === 8) return 31
			if(mth === 9) return 30
			if(mth === 10) return 31
			if(mth === 11) return 30
			if(mth === 12) return 31
		},
		sortArrayOfObjects(arr, sortColName, sortColOrder = 'asc') {
			let useStrings = false;
			if(isNaN(arr[0][sortColName]) || parseFloat(arr[0][sortColName]) != arr[0][sortColName]) useStrings = true;
			if(sortColOrder.toLowerCase() === 'asc') {
				if(useStrings === true) {
					arr = arr.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
				}
				else arr = arr.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
			}
			else {
				if(useStrings === true) arr = arr.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
				else arr = arr.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
			}
			return arr;
		},
		getDayOfWeekStringToInt(dayOfWeekStr) {
			if(dayOfWeekStr.includes('Sun')) return 0
			else if(dayOfWeekStr.includes('Mon')) return 1
			else if(dayOfWeekStr.includes('Tue')) return 2
			else if(dayOfWeekStr.includes('Wed')) return 3
			else if(dayOfWeekStr.includes('Thu')) return 4
			else if(dayOfWeekStr.includes('Fri')) return 5
			else if(dayOfWeekStr.includes('Sat')) return 6
			return null
		},
		getNiceDate(str, includeYear = true) { //YYYY-MM-DD
			let ar = str.split("-")
			let out = this.getMonthName(ar[1]) + ' ' + ar[2]
			if(includeYear) out += ', ' + ar[0]
			return out
		},
		//tables stuff
		tblPrepareFilteredRows(rows, filterText = '', sortColName = null, sortColOrder = 'desc') {
			if(filterText.length > 0) { //add any filter by text input here
				let newer = [];
				let sboxval = filterText.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(typeof(r[fld]) === 'string' && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			if(sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][sortColName]) || parseFloat(rows[0][sortColName]) != rows[0][sortColName]) useStrings = true;
				if(sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
				}
			}
			return rows;
		},
		tblGetPaginatedRows(rows, paginationRowsPerPage = 25) {
			if(!paginationRowsPerPage) return [rows]
			else {
				let tempArr = []
				let tempSubArr = []
				for(let it of rows) {
					tempSubArr.push(it)
					if(tempSubArr.length === paginationRowsPerPage) {
						tempArr.push(tempSubArr)
						tempSubArr = []
					}
				}
				if(tempSubArr.length > 0) tempArr.push(tempSubArr)
				return tempArr
			}
		},
		//end tables stuff
		getInitialObjects() {
			if(this.store.fetching === false) {
				this.store.fetching = true;
				let sendob = {
					uid: this.store.user.id,
					kevsesstoken: this.store.kevsesstoken,
				};
				let self = this;
				this.store.showLoader = true;
				axios.post("/post/get-initial-objects.php", JSON.stringify(sendob)).then(function (response) {
					let ret = response.data;
					if(ret.error && ret.error === 1) {
						self.showKalertError(ret.errob);
					}
					if(ret.users) self.store.users = ret.users
					if(ret.markets) self.store.markets = ret.markets
					if(ret.stations) self.store.stations = ret.stations
					if(ret.asset_types) self.store.assetTypes = ret.asset_types
					if(ret.asset_subtypes) self.store.assetSubtypes = ret.asset_subtypes
					if(ret.assets) self.store.assets = ret.assets
					if(ret.bookings) self.store.bookings = ret.bookings
					if(ret.booking_parents) self.store.booking_parents = ret.booking_parents
					self.prepareAssetItems()
					self.store.initialObjectsFetched = true
					self.store.showLoader = false;
					self.store.fetching = false;
				}).catch(function (error) {
					console.log(error);
					self.showKalertError({message: error});
					self.store.showLoader = false;
				});
			}
		},
		prepareAssetItems() { //run after initial fetch, bookings or assets are updated
			let todayDate = new Date()
			let todayDayOfWeek = todayDate.getDay()
			let lastMondayDate = new Date()
			lastMondayDate.setDate(todayDate.getDate() - (todayDayOfWeek - 1))
			if(todayDayOfWeek === 0) { //sunday
				lastMondayDate.setDate(todayDate.getDate() - 6)
			}
			let lastMondayStr = lastMondayDate.getFullYear()+"-"+this.ldzero(lastMondayDate.getMonth()+1)+"-"+lastMondayDate.getDate()
			let finalDate = new Date(new Date().setDate(new Date(lastMondayStr).getDate()+370))
			let finalDateStr = finalDate.getFullYear()+"-"+this.ldzero(finalDate.getMonth()+1)+"-"+finalDate.getDate()
			let daterange = this.createDateRangeBetween(lastMondayStr, finalDateStr)
			this.store.assetsExtended = []
			for(let r of this.store.assets) {
				let a = new AssetClass()
				// a.prepareItemDays(r, this.store.bookings.filter(item => item.assetId === r.id), daterange)
				a.prepareItemWeeks(r, this.store.bookings.filter(item => item.assetId === r.id), daterange)
				this.store.assetsExtended.push(a)
			}
		},
		getMarketNameFromId(mktid) {
			mktid = parseInt(mktid)
			if(mktid === 0) return ''
			let ob = this.store.markets.find(item => item.id === mktid)
			if(ob) return ob.name
			return ''
		},
		getAssetTypeNameFromId(tid) {
			tid = parseInt(tid)
			if(tid === 0) return ''
			let ob = this.store.assetTypes.find(item => item.id === tid)
			if(ob) return ob.name
			return ''
		},
		getAssetSubtypeNameFromId(tid) {
			tid = parseInt(tid)
			if(tid === 0) return ''
			let ob = this.store.assetSubtypes.find(item => item.id === tid)
			if(ob) return ob.name
			return ''
		},
		getStationNameFromId(tid) {
			tid = parseInt(tid)
			if(tid === 0) return ''
			let ob = this.store.stations.find(item => item.id === tid)
			if(ob) return ob.name
			return ''
		},
		getRepNameFromId(tid) {
			tid = parseInt(tid)
			if(tid === 0) return ''
			let ob = this.store.users.find(item => item.id === tid)
			if(ob) return ob.fname + ' ' + ob.lname
			return ''
		},
		getAssetNameFromId(tid) {
			tid = parseInt(tid)
			if(tid === 0) return ''
			let ob = this.store.assets.find(item => item.id === tid)
			if(ob) return ob.name
			return ''
		},
		getStationNameFromAssetId(tid) {
			tid = parseInt(tid)
			if(tid === 0) return ''
			let ob = this.store.assets.find(item => item.id === tid)
			if(ob) {
				let stn = this.store.stations.find(item => item.id === ob.stationId)
				return stn.name
			}
			return ''
		},
	}

}