<template>
	<div class="wrapper-inside">
		<p style="font-size: 14px;">You do not have permission to access this page</p>
	</div>
</template>

<script>
export default {
	name: "NoPermission",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>