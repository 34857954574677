<template>
	<span class="clvails" :class="{sold : isSold}" :title="title">
		<span v-if="isSold" style="width: 100%; height: 100%; display: block;"></span>
	</span>
</template>

<script>
import {globalMixin} from "@/mixins";
export default {
	name: "TrackerWeekCell",
	components: {},
	mixins: [globalMixin],
	props: {
		wk: Object,
	},
	data: function () {
		return {}
	},
	computed: {
		isSold() {
			if(this.wk.weekSoldStatus === 'sold') return true
			return false
		},
		title() {
			let r = 'Sold: '+this.wk.nSold+' of '+this.wk.maxAvail+". Pending: "+this.wk.nPending+".\n";
			for(let c of this.wk.bookings) {
				r += c.clientName + ' (' + this.getNiceDate(c.startdate, false) + ' to ' + this.getNiceDate(c.enddate, false) + ')\n'
			}
			return r
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.clvails {
	display: inline-block;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: var(--available);
	margin-right: 3px;
}
.clvails.sold {
	background-color: var(--sold);
}
</style>