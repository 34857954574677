<template>
	<div class="" style="min-height: 100vh;">
		Logging out...
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: 'LogoutPage',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Logging out...";
	},
	created() {
		this.clearLoginCredsAndSession();
	},
}
</script>

<style scoped>

</style>
