<template>
	<div>
		<span class="page-move" @click="prevpage" :class="{disable : paginationpage <= 1}">Prev</span>
	</div>
	<div v-if="paginatedrowscount < 10">
		<span class="pager" v-for="index in paginatedrowscount" :key="index" @click="mypaginationpage = index" :class="{current : paginationpage === index}">{{index}}</span>
	</div>
	<div v-else>
		<select v-model="mypaginationpage" style="padding: 2px 5px; margin-right: 5px;">
			<option v-for="n in paginatedrowscount" :key="n" :value="n">{{n}}</option>
		</select>
	</div>
	<div>
		<span class="page-move" @click="nextpage" :class="{disable : paginationpage >= paginatedrowscount.length}">Next</span>
	</div>
</template>

<script>
export default {
	name: "ALTablePaginator",
	components: {},
	props: {
		paginationpage: Number,
		paginatedrowscount: Number,
	},
	emits: ['nextpage', 'prevpage', 'setpage'],
	data: function () {
		return {
			mypaginationpage: null,
		}
	},
	computed: {},
	methods: {
		nextpage() {
			this.$emit('nextpage')
		},
		prevpage() {
			this.$emit('prevpage')
		},
	},
	watch: {
		mypaginationpage() {
			this.$emit('setpage', this.mypaginationpage)
		},
		paginationpage() {
			this.mypaginationpage = this.paginationpage
		},
	},
	mounted() {
		this.mypaginationpage = this.paginationpage
	}
}
</script>

<style scoped>
.page-move {
	cursor: pointer;
	border: 1px solid #CCC;
	margin: 0 5px 0 0;
	padding: 3px 5px;
	border-radius: 2px;
	transition: background-color 0.2s ease-in-out;
}
.page-move.disable {
	cursor: unset;
	border: 1px solid #CCC;
	color: #CCC;
}
.page-move:not(.disable):hover {
	background-color: #DDD;
}
.pager {
	cursor: pointer;
	border: 1px solid #CCC;
	margin: 0 5px 0 0;
	padding: 3px 5px;
	border-radius: 2px;
	transition: background-color 0.2s ease-in-out;
}
.pager.current {
	border: 1px solid #000;
	font-weight: bold;
}
.pager:not(.current):hover {
	background-color: #DDD;
}
</style>