<template>
	<div>
		<LoaderOverlay v-if="store.showLoader"></LoaderOverlay>
		<AlertTop v-if="store.kalert.shown"></AlertTop>
		<router-view></router-view>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import AlertTop from "@/components/AlertTop";
import LoaderOverlay from "@/components/LoaderOverlay";
import {globalMixin} from "@/mixins";

export default {
	name: 'App',
	components: {
		LoaderOverlay,
		AlertTop
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
		}
	},
	computed: {
		sessionOk() {
			return this.store.sessionOk;
		}
	},
	methods: {
		checkSessionOk() {
			let self = this;
			axios.post(
				self.store.urlroot + "/post/sesscheck.php",
				JSON.stringify({
					uid: this.store.user.id,
					kevsesstoken: this.store.kevsesstoken,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.sessionok !== true) { //transfer to login screen
					window.sessionStorage.removeItem('kevSessToken');
					window.sessionStorage.removeItem('kevUserId');
					if(self.$router.currentRoute.value.path !== '/') {
						if(window.location.pathname.length > 2 && window.location.pathname !== "/" && !window.location.pathname.includes('logout')) {
							self.$router.push("/?ref="+encodeURIComponent(window.location.pathname));
						}
						else self.$router.push("/"); //to login page
					}
				}
				else { //we are OK
					self.store.user = ret.user;
					self.store.sessionOk = true;
					if(self.$router.currentRoute.value.path === '/') { //if we are on the login page, go to home
						self.$router.push("/dashboard"); //to home landing page
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	mounted() {
		if(this.store.kevsesstoken.length === 0 && window.sessionStorage.getItem('kevSessToken')) {
			this.store.kevsesstoken = window.sessionStorage.getItem('kevSessToken');
		}
		if(this.store.user.id === 0 && window.sessionStorage.getItem('kevUserId')) {
			this.store.user.id = parseInt(window.sessionStorage.getItem('kevUserId'));
		}
		if(this.store.user.id > 0 && this.store.kevsesstoken.length > 0) {
			this.checkSessionOk();
		}
		else if(window.location.pathname !== '/') {
			if(window.location.pathname.length > 2 && window.location.pathname !== "/" && !window.location.pathname.includes('logout')) {
				this.$router.push("/?ref="+encodeURIComponent(window.location.pathname));
			}
			else {
				this.$router.push("/"); //to login page
			}
		}
	},
	watch: {
		sessionOk() { //this will change on do-login and sesscheck (F5) once approved
			// if(this.store.sessionOk === true) //do something here if necessary
		},
	},
}
</script>

<style>
:root {
	--grey: #E2E2E2;
	--lightGrey: #F7F8FA;
	--lightGreyHover: #D7D8DA;
	--midGrey: #AAA;
	--darkGrey: #888;
	--blue: #1E3152;
	--darkBlue: #0E2240;
	--white: #FFFFFF;
	--red: #FF0000;

	--sold: #DD0E66;
	--sold-light: #FCE4E9;
	--available: #00B289;
	--available-light: #DEF4F1;
	--pending: #8D97A7;
	--pending-light: #DCF1FA;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
	-webkit-padding-end: 0;
	-webkit-margin-after: 0;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
    width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}
body {
	font-family: 'Roboto', sans-serif;
	margin: 0;
	height: 100%;
	font-size: 13px;
	color: #444;
}
a {
	cursor: pointer;
	outline: 0;
	text-decoration: none;
}
input {
	font-family: inherit;
	border: none;
	padding: 5px 8px;
}
button {
	outline: 0;
	font-family: inherit;
}
h1 {
	font-size: 38px;
	font-weight: bold;
	color: var(--blue);
}
h2 {
	font-size: 24px;
	font-weight: bold;
	color: var(--blue);
}
.wrapper {
	background-color: #FFFFFF;
	transition: padding 0.2s ease-in-out;
	min-height: 100vh;
	padding-left: 100px;
}
.wrapper.open {
	padding-left: 230px;
}
.wrapper-inside {
	padding: 60px 80px;
}
.title-section {
	display: flex;
	margin-bottom: 40px;
	justify-content: space-between;
	align-items: flex-start;
}
.flex {
	display: flex;
}
.half {
	width: 49%;
}
.option-button {
	border-radius: 6px;
	background: var(--blue);
	padding: 8px 24px;
	color: #fff;
	font-size: 15px;
    font-weight: 700;
	border: none;
	transition: ease-in-out 0.5s;
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.option-button:hover {
	background: var(--darkBlue);
}
.option-button.cancel-button {
	background-color: var(--midGrey);
}
.option-button.cancel-button:hover {
	background-color: var(--darkGrey);
}
.option-button.delete-button {
	background-color: var(--red);
}
.option-button svg {
    margin-left: 5px;
}
/** working/loading buttons */
.button-is-working {
	cursor: not-allowed !important;
	color: transparent !important;
}
@keyframes xui-loader-animation {
	0%,80%,100% {transform:scale(0.5) rotate(0deg)}
	40%{transform:scale(1) rotate(0.02deg)}
}
/** end working/loading buttons */
.mx-datepicker,
.mx-datepicker input {
    width: 100% !important;
}
.mx-icon-calendar,
.mx-icon-clear {
    top: 40% !important
}
.mx-input:disabled {
	color: #000;
	background-color: #FAFAFA;
}
.align-right {
	text-align: right;
}
/** forms **/
.form-headline {
	font-size: 14px;
	margin-bottom: 10px;
}
.form-input-group {
	margin-bottom: 20px;
	border-radius: 6px;
	font-size: 16px;
}
.form-input-fail {
	color: #F00;
	font-size: 12px;
	text-align: left;
	display: none;
}
.form-input-fail.shown {
	display: block;
}
.form-input-group input,
.form-input-group select,
.form-input-group input:disabled,
.form-input-group select:disabled {
	margin-bottom: 10px;
	border: 1px solid #CCC;
	border-radius: 6px;
	font-size: 16px;
	color: #000;
}
.form-input-group input {
	padding: 10px 8px;
	width: calc(100% - 22px);
}
.form-input-group select {
	padding: 10px 5px;
	width: calc(100% - 6px);
}
.form-input-group textarea {
	margin-bottom: 10px;
	padding: 10px 8px;
	width: calc(100% - 18px);
	border: 1px solid #CCC;
	border-radius: 6px;
	font-size: 16px;
	font-family: 'Roboto';
}
.form-check-hold {
	display: flex;
	align-items: center;
	padding: 3px 0;
}
input.form-check {
	margin-right: 5px;
}
.form-check-label {
	font-size: 14px;
}
/** end forms **/
/** 2022 AL Tables **/
.filterBox {
	color: #1E3152;
	font-size: 14px;
	background-color: #fff;
	border: 1px solid #8F97A6;
	padding: 9px 8px;
}
.altable-holder {
	width: 100%;
	margin: 0;
}
.altbl-container {
	/*overflow-x: auto;*/
}
.altbl {
	font-size: 13px;
}
.altbl td {
    color: #1E3152;
	border-left: 1px solid #DDD;
	border-right: 1px solid #DDD;
    padding: 14px 25px;
}
.altbl.noborderv td {
	border-left: none;
	border-right: none;
}
.altbl thead {

}
.altbl thead tr {
	background-color: #E4E6EA;
}
.altbl thead tr td {
    color: #1E3152;
    font-size: 17px;
    font-weight: 700;
	padding: 13px 25px;
	cursor: pointer;
}
.altbl tbody tr {
    font-size: 15px;
	background-color: transparent;
	transition: background-color 0.2s ease-in-out;
	border-bottom: 1px solid #DDD;
}
.altbl.striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0,0,0,0.05);
	border-bottom: none;
}
.altbl tbody tr:hover {
	background-color: rgba(0,0,0,0.075);
}
.altbl tbody tr {
	background-color: transparent;
}
.altbl .subtitlerow {
	background-color: #EEE;
	font-weight: bold;
	border-top: 1px solid #888;
	border-bottom: 1px solid #888;
}
.altbl tr.subtitlerow td {
	padding: 9px 8px 8px;
}
.altbl .headingtext {
	font-weight: bold;
}
.altbl .rt {
	text-align: right;
}
.altbl .ctr {
	text-align: center;
}
.action-icon {
	font-size: 16px;
	margin: 5px;
	cursor: pointer;
}
.action-icon:hover {
	opacity: 0.6;
}
/** End 2022 AL Tables **/
/** div table replacements **/
.altbldiv {
	box-sizing: border-box;
}
.altbldiv .trow {
	display: flex;
}
.altbldiv .thead .trow {
	border-bottom: 1px solid #DDD;
	font-weight: 500;
	text-transform: uppercase;
	background-color: var(--lightGrey);
	display: flex;
}
.altbldiv .thead .trow .tcell:first-of-type {
	flex: 2;
}
.altbldiv .tbody .trow {
	font-size: 15px;
	background-color: transparent;
	transition: all 0.2s ease-in-out;
	border-bottom: 1px solid #DDD;
}
.altbldiv .tbody .trow .tcell:first-of-type {
	flex: 2;
}
.altbldiv .tbody .trow:hover {
	background-color: var(--lightGrey);
}
.altbldiv .tcell {
	color: #1E3152;
	/*border-left: 1px solid #DDD;*/
	/*border-right: 1px solid #DDD;*/
	padding: 14px 15px;
	flex: 1;
	display: flex;
	align-items: center;
	line-height: 1.2;
}
.altbldiv.noborderv .tcell {
	/*border-left: none;*/
	/*border-right: none;*/
}
/*.altbldiv .tcell.flex {*/
/*	display: flex;*/
/*	padding: 0;*/
/*	border-left: none;*/
/*	border-right: none;*/
/*}*/

/** end div table replacements **/
.popup-tabs {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 11px;
	font-family: 'Roboto';
	background-color: #F1F2F4;
}
.popup-tab {
    font-size: 15px;
    font-weight: 400;
    background-color: #E4E6EA;
	padding: 12px 20px;
	margin-right: 5px;
	cursor: pointer;
	border-radius: 5px 5px 0 0;
}
.popup-tab.active {
	background: #FFF;
}
.popup-tab:not(.active):hover {
	background-color: #EEE;
}
.contained-popup-content {
	max-height: 500px;
	min-height: 200px;
	overflow-y: auto;
	position: relative;
}
.mobile {
	display: none;
}

@media screen and (max-width: 1200px) {
	.wrapper.open {
		padding-left: 100px;
	}
}
@media screen and (max-width: 1100px) {
	.wrapper-inside {
		padding: 60px 50px;
	}
	.thead .trow .tcell {
		font-size: 13px;
	}
	.tbody .trow .tcell {
		font-size: 14px;
	}
	.altbl tbody tr {
		font-size: 13px;
	}
	.altbl thead tr td {
		font-size: 13px;
		padding: 10px 10px;
	}
	.altbl td {
		padding: 10px 10px;
	}
}
@media screen and (max-width: 840px) {
	.wrapper.open {
		padding-left: 0;
	}
	.wrapper-inside {
		padding: 40px 40px 120px 40px;
	}
	.mobile, .altbldiv .tcell.mobile {
		display: block;
	}
	.no-mobile, .altbldiv .tcell.no-mobile {
		display: none;
	}
}

@media screen and (max-width: 700px) {
	.wl-popup {
		max-width: 80% !important;
	}
	.wl-popup .form-input-group {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.wl-popup .flex {
		flex-wrap: wrap;
	}
	.wl-popup .half {
		width: 100%;
		flex-shrink: 0;
	}
}
</style>