<template>
	<div>

        <BookingEditPopup v-if="editBookingPopupShown" :bookingobin="selectedBookingOb" v-on:close="closeEditBookingPopup" />

		<LeftMenu></LeftMenu>
		<div class="wrapper" :class="{open : store.leftmenuhidden === false}">
			<div class="wrapper-inside">
				<div class="title-section">
					<h1>Manage Bookings</h1>
				</div>
                <div class="altable-holder">
                    <div style="display: flex; align-items: center; justify-content: flex-start; padding: 5px 0;">
                        <div class="" style="margin: 5px 0;">
                            <input type="text" style="width: 300px;" class="filterBox" v-model="filterText" placeholder="Search bookings..." />
                        </div>
						<div style="margin-left: 10px;">
							<select v-model="repFilterId" style="padding: 8px 10px; font-size: 14px; border: 1px solid #666;">
								<option :value="null">All Reps</option>
								<option v-for="u in store.users" :key="u.id" :value="u.id">
									{{u.fname}} {{u.lname}}
								</option>
							</select>
						</div>
						<div style="margin-left: 10px;">
							<select v-model="statusFilterId" style="padding: 8px 10px; font-size: 14px; border: 1px solid #666;">
								<option value="all">All (excl Deleted)</option>
								<option value="submitted">New / Submitted</option>
								<option value="sold">Sold</option>
								<option value="pending">Pending</option>
								<option value="awaitcredits">Awaiting Credits</option>
							</select>
						</div>
                    </div>
                    <br/>
                    <table class="altbl noborderv">
                        <thead>
                        <tr>
                            <td class="headingtext minwid">Rep</td>
                            <td class="headingtext minwid">Station</td>
                            <td class="headingtext minwid">Asset</td>
                            <td class="headingtext">Client</td>
                            <td class="headingtext">Status</td>
                            <td class="headingtext">Dates</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="rowitem in rowsForPage" :key="rowitem.id" style="cursor: pointer;" @click="editBooking(rowitem)"
							:title="rowitem.description"
							:class="{newbooking : rowitem.status === 'submitted', deletedbooking : rowitem.status === 'deleted', pendingbooking : rowitem.status === 'pending'}"
						>
                            <td>{{getRepNameFromId(rowitem.repId)}}</td>
                            <td>{{getStationNameFromAssetId(rowitem.assetId)}}</td>
                            <td>{{getAssetNameFromId(rowitem.assetId)}}</td>
                            <td>{{rowitem.clientName}}</td>
                            <td>
								{{ucfirst(rowitem.status)}}
								<span v-if="rowitem.awaiting_credits === true">***</span>
							</td>
                            <td>{{ rowitem.startdate }} to {{ rowitem.enddate }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div style="margin: 30px 0; display: flex; align-items: center;">
                        <ALTablePaginator
                            :paginationpage="paginationPage"
                            :paginatedrowscount="paginatedRows.length"
                            v-on:nextpage="nextpage" v-on:prevpage="prevpage"
                            v-on:setpage="paginationPage = $event"
                        ></ALTablePaginator>
                    </div>

                </div>
			</div>
		</div>
		<BottomMobileMenu></BottomMobileMenu>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import LeftMenu from "@/components/LeftMenu";
import BookingEditPopup from "@/components/BookingEditPopup";
import ALTablePaginator from "@/components/ALTablePaginator";
import BottomMobileMenu from "@/components/BottomMobileMenu";

export default {
	name: "BookingsPage",
	components: {
		BottomMobileMenu,
		ALTablePaginator,
		BookingEditPopup,
		LeftMenu
	},
	props: {},
	mixins: [globalMixin],
    data: function () {
        return {
            store: store,
            // table stuff
            filterText: '',
            sortColName: null,
            sortColOrder: 'desc',
            paginationRowsPerPage: 20,
            paginationPage: 1,
            //end table stuff
			repFilterId: null,
			statusFilterId: 'all',

            editBookingPopupShown: false,
            selectedBookingOb: {},
        }
    },
    computed: {
        //table stuff
        rows() {
			let filteredItemsByRep = this.store.bookings
			if(this.repFilterId) filteredItemsByRep = this.store.bookings.filter(item => item.repId === this.repFilterId)
			if(this.statusFilterId === 'all') filteredItemsByRep = filteredItemsByRep.filter(item => item.status !== 'deleted')
			else if(this.statusFilterId === 'awaitcredits') filteredItemsByRep = filteredItemsByRep.filter(item => item.awaiting_credits === true)
			else filteredItemsByRep = filteredItemsByRep.filter(item => item.status === this.statusFilterId)
            return this.tblPrepareFilteredRows(filteredItemsByRep, this.filterText, this.sortColName, this.sortColOrder)
        },
        paginatedRows() {
            return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
        },
        rowsForPage() {
            if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
            return this.paginatedRows[this.paginationPage-1]
        },
        //end table stuff
    },
    methods: {
        //table stuff
        sortColumn(colfldid) {
            if(this.sortColName === colfldid) { //reverse the already active sort
                if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
                else this.sortColOrder = 'asc'
            }
            else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
        },
        prevpage() {
            if(this.paginationPage > 1) this.paginationPage--
        },
        nextpage() {
            if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
        },
        //end table stuff
        editBooking(bookingob) {
            this.selectedBookingOb = bookingob;
            this.editBookingPopupShown = true;
        },
		closeEditBookingPopup() {
            this.selectedBookingOb = null;
            this.editBookingPopupShown = false;
        },
    },
    watch: {
        rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
            this.paginationPage = 1
        },
    },
	mounted() {
		if(this.store.user.isAdmin !== 1) this.repFilterId = this.store.user.id
	},
    created() {
        if(!this.store.initialObjectsFetched) this.getInitialObjects()
    }
}
</script>

<style scoped>
.newbooking {
	background: rgba(221,14,102,0.3);
}
.newbooking:hover {
	background: rgba(221,14,102,0.5);
}
.pendingbooking {
	background: var(--pending-light);
}
.deletedbooking {
	background: rgba(255,255,255,0.2);
	font-style: italic;
}
.altbl .deletedbooking  td {
	color: #CCC;
}
</style>