<template>
	<BlanketOverlayWithPopup v-if="bookingobin" :title="popupTitle" width="1000" v-on:close="closeCancel" :hastabs="true">
		<template v-slot:tabs>
			<div class="popup-tabs">
				<div class="popup-tab" :class="{active : activeTab === 'main'}" @click="activeTab = 'main'">Main</div>
				<div class="popup-tab" :class="{active : activeTab === 'booked_items'}" @click="activeTab = 'booked_items'">Booked Items</div>
				<div class="popup-tab" :class="{active : activeTab === 'notes'}" @click="activeTab = 'notes'">Credits/Notes</div>
				<div v-if="store.user.isAdmin === 1" class="popup-tab" :class="{active : activeTab === 'logging'}" @click="activeTab = 'logging'">History</div>
			</div>
		</template>
		<template v-slot:main>
			<div v-if="activeTab === 'main'" class="contained-popup-content">
				<div class="form-input-group">
					<p class="form-headline">Rep</p>
					<select v-model="bookingEditOb.repId" :disabled="store.user.isAdmin !== 1">
						<option v-for="u in store.users" :key="u.id" :selected="u.id === bookingEditOb.repId" :value="u.id">
							{{u.fname}} {{u.lname}}
						</option>
					</select>
				</div>
				<div class="form-input-group">
					<p class="form-headline">Client Name</p>
					<input v-model="bookingEditOb.clientName"  :disabled="store.user.isAdmin !== 1" />
				</div>
				<div class="form-input-group">
					<p class="form-headline">Status</p>
					<select v-model="bookingEditOb.status" :disabled="store.user.isAdmin !== 1">
						<option>Select Status</option>
						<option v-for="(status, index) in statuses" :value="status" :key="index" :selected="status === bookingEditOb.status">
							{{ ucfirst(status) }}
						</option>
					</select>
				</div>
			</div>

			<div v-if="activeTab === 'booked_items'" class="contained-popup-content">
				<div v-for="item in bookedItems" :key="item.id" class="booking-item-in-list" @click="editBookingLine(item)">
					{{getStationNameFromAssetId(item.assetId)}}: {{getAssetNameFromId(item.assetId)}}.
					{{item.startdate}} to {{item.enddate}}
				</div>
			</div>

			<div v-if="activeTab === 'notes'" class="contained-popup-content">
				<div class="form-input-group">
					<div>
						<input type="checkbox" style="display: inline; width: unset; margin-right: 5px;" v-model="bookingEditOb.awaiting_credits" />
						<span >Waiting on credit lines</span>
					</div>
				</div>

				<div class="form-input-group">
					<p class="form-headline">Credit Lines Copy</p>
					<textarea v-model="bookingEditOb.creditLines" rows="6"></textarea>
				</div>

				<div class="form-input-group">
					<p class="form-headline">Sales Notes</p>
					<textarea v-model="bookingEditOb.salesNotes" rows="6"></textarea>
				</div>

				<div v-if="bookingEditOb.attachFilename && bookingEditOb.attachFilename.length > 0" style="padding: 20px 0;">
					<p class="form-headline">Attachment</p>
					<a target="_blank" :href="'/protected/uploads/'+bookingEditOb.attachFilepath+'/'+bookingEditOb.attachFilename">{{bookingEditOb.attachFilename}}</a>
				</div>

				<div v-if="store.user.isAdmin !== 1">
					<div v-if="store.user.id === bookingEditOb.repId">
						<button type="button" class="option-button" @click="saveBookingInfo">Save Update</button>
					</div>
					<div v-else>You cannot save changes to this booking as you are not the owner</div>
				</div>
			</div>

			<div v-if="activeTab === 'logging'" class="contained-popup-content">
				<LoggingItem v-for="li in loggingItems" :key="li.ai" :logitem="li"></LoggingItem>
			</div>

        </template>
		<template v-slot:buttons>
			<button type="button" class="option-button cancel-button" @click="closeCancel">
				<span v-if="store.user.isAdmin === 1">Cancel</span>
				<span v-else>Close</span>
			</button>
			<button v-if="store.user.isAdmin === 1" type="button" class="option-button" @click="saveBookingInfo">Save Info</button>
		</template>
	</BlanketOverlayWithPopup>

	<BookingEditPopup v-if="showingBookingItemPopup && liveBookingItem" :bookingobin="liveBookingItem" v-on:close="closeEditBookingPopup"></BookingEditPopup>

</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import LoggingItem from "@/components/LoggingItem";
import BookingEditPopup from "@/components/BookingEditPopup";

export default {
	name: "BookingParentEditPopup",
	components: {
		BookingEditPopup,
		LoggingItem,
		BlanketOverlayWithPopup,
	},
	mixins: [globalMixin],
	props: {
		bookingobin: Object,
	},
	emits: ['close'],
	data: function () {
		return {
			store: store,
			isFetching: false,

			stationFilterId: null,
			activeTab: 'main',

            bookingEditOb: {},
			loggingItems: [],
			statuses: ['pending', 'sold', 'submitted', 'deleted'],

			liveBookingItem: null,
			showingBookingItemPopup: false,
		}
	},
	computed: {
		availableAssets() {
			if(this.stationFilterId) {
				return this.store.assets.filter(item => item.stationId === this.stationFilterId)
			}
			return []
		},
		popupTitle() {
			if(this.store.user.isAdmin === 1) return 'Booking Group Editor'
			else return 'Booking Group Detail'
		},
		bookedItems() {
			return this.store.bookings.filter(item => item.parent_id === this.bookingEditOb.id)
		}
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveBookingInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true
				this.isFetching = true
                let targ = "/post/save-booking-parent.php"
				let myob = {};
				let fields = ['id','repId','clientName','status','creditLines','salesNotes','awaiting_credits']
				for(let pr in this.bookingEditOb) {
					if(fields.includes(pr)) myob[pr] = this.bookingEditOb[pr]
				}
				let sendob = {bookingob: myob}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data if needed
					if(ret.bookings) this.store.bookings = ret.bookings
					if(ret.booking_parents) this.store.booking_parents = ret.booking_parents
					this.isFetching = false;
					this.store.showLoader = false;
					this.$emit('close');
				}.bind(this));
			}
		},
		getLoggingInfo() {
			let targ = '/post/get-logging.php'
			let sendob = {type: 'booking_parent', id: this.bookingEditOb.id}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data if needed
				if(ret.loggingItems) {
					this.loggingItems = ret.loggingItems
				}
			}.bind(this));
		},
		populateContent() {
			if(this.bookingobin) {
				let assob = this.store.assets.find(item => item.id === this.bookingobin.assetId)
				if(assob) this.stationFilterId = assob.stationId
				for(let pr in this.bookingobin) {
					this.bookingEditOb[pr] = this.bookingobin[pr];
				}
				this.getLoggingInfo()
			}
		},
		editBookingLine(item) {
			this.liveBookingItem = item
			this.showingBookingItemPopup = true
		},
		closeEditBookingPopup() {
			this.liveBookingItem = null;
			this.showingBookingItemPopup = false;
		},
	},
	watch: {
        bookingobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	}
}
</script>
<style>
@media screen and (max-width: 1100px) {
	.wl-popup {
		max-width: 80% !important;
	}
	.wl-popup .form-input-group {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.wl-popup .form-input-group p {
		font-size: 14px !important;
	}
	.form-input-group input, .form-input-group select {
		font-size: 14px !important;
	}
	.wl-popup .flex {
		flex-wrap: wrap;
	}
	.wl-popup .half {
		width: 100%;
		flex-shrink: 0;
	}
}
</style>
<style scoped>
.form-input-group {
    display: flex;
    align-items: center;
}
.form-input-group p {
    font-size: 17px;
    width: 140px;
}
.booking-item-in-list {
	border: 1px solid #CCC;
	padding: 10px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.booking-item-in-list:hover {
	background: var(--lightGrey);
}
</style>