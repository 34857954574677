<template>
	<div class="cal-week" :class="cls" :title="title">

	</div>
</template>

<script>
import {globalMixin} from "@/mixins";
export default {
	name: "CalendarWeek",
	components: {},
	mixins: [globalMixin],
	props: {
		weekitem: Object,
		maxsim: Number,
	},
	data: function () {
		return {}
	},
	computed: {
		cls() {
			let cl = []
			if(this.weekitem.weekSoldStatus !== null) {
				cl.push(this.weekitem.weekSoldStatus)
			}
			if(this.weekitem.nSold > this.weekitem.maxAvail) {
				cl.push('oversold')
			}
			return cl.join(" ")
		},
		title() {
			let t = this.getNiceDate(this.weekitem.dt, false) + '\n'
			if(this.weekitem.bookings && this.weekitem.bookings.length > 0) {
				for(let db of this.weekitem.bookings) {
					t += '\n'+this.ucfirst(db.status)+': ' + db.clientName + ' (' + this.getNiceDate(db.startdate, false) + ' to ' + this.getNiceDate(db.enddate, false) + ')'
				}
			}
			else {
				t += '\nAvailable'
			}
			return t
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>