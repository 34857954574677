<template>
	<div>

		<UserEditPopup v-if="editUserPopupShown" :userobin="selectedUserOb" v-on:close="closeEditUserPopup"></UserEditPopup>

		<LeftMenu></LeftMenu>
		<div class="wrapper" :class="{open : store.leftmenuhidden === false}">
			<div v-if="store.user.isAdmin === 1" class="wrapper-inside">
				<div class="title-section">
					<h1>User Admin</h1>
				</div>
				<div class="altable-holder">
					<div style="display: flex; align-items: center; justify-content: flex-start; padding: 5px 0;">
						<div class="" style="margin: 5px 0;">
							<input type="text" style="width: 300px;" class="filterBox" v-model="filterText" placeholder="Search users..." />
						</div>
						<button @click="addNewUser" style="margin-left: 15px;" type="button" class="option-button">Add New User</button>
					</div>
					<br/>
					<table class="altbl noborderv">
						<thead>
						<tr>
<!--							<td class="headingtext" @click="sortColumn('id')">ID</td>-->
							<td class="headingtext minwid" @click="sortColumn('email')">Email</td>
							<td class="headingtext minwid" @click="sortColumn('fname')">First Name</td>
							<td class="headingtext minwid" @click="sortColumn('lname')">Last Name</td>
<!--							<td class="headingtext">Market</td>-->
							<td class="headingtext">Status</td>
							<td class="headingtext ctr" @click="sortColumn('isAdmin')">Admin</td>
						</tr>
						</thead>
						<tbody>
						<tr v-for="rowitem in rowsForPage" :key="rowitem.id" @click="editUser(rowitem)" style="cursor: pointer;">
<!--							<td>{{rowitem.id}}</td>-->
							<td>{{rowitem.email}}</td>
							<td>{{rowitem.fname}}</td>
							<td>{{rowitem.lname}}</td>
<!--							<td>{{getMarketNameFromId(rowitem.marketId)}}</td>-->
							<td>{{ucfirst(rowitem.status)}}</td>
							<td class="ctr">{{intToYes(rowitem.isAdmin)}}</td>
<!--							<td>-->
<!--								<span class="action-icon" title="Send password reminder email" @click.stop="emailPassRemind(rowitem)"><i class="mdi mdi-at"></i></span>-->
<!--								<span class="action-icon" title="Clone user" @click.stop="cloneUserToNew(rowitem)"><i class="mdi mdi-content-copy"></i></span>-->
<!--							</td>-->
						</tr>
						</tbody>
					</table>

					<div style="margin: 30px 0; display: flex; align-items: center;">
						<ALTablePaginator
								:paginationpage="paginationPage"
								:paginatedrowscount="paginatedRows.length"
								v-on:nextpage="nextpage" v-on:prevpage="prevpage"
								v-on:setpage="paginationPage = $event"
						></ALTablePaginator>
					</div>

				</div>

			</div>
			<NoPermission v-else></NoPermission>
		</div>
		<BottomMobileMenu></BottomMobileMenu>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import LeftMenu from "@/components/LeftMenu";
import NoPermission from "@/components/NoPermission";
import ALTablePaginator from "@/components/ALTablePaginator";
import UserEditPopup from "@/components/UserEditPopup";
import BottomMobileMenu from "@/components/BottomMobileMenu";

export default {
	name: "UsersPage",
	components: {
		BottomMobileMenu,
		UserEditPopup,
		ALTablePaginator,
		NoPermission,
		LeftMenu
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			//end table stuff

			editUserPopupShown: false,
			selectedUserOb: {},
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.store.users, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		editUser(userob) {
			this.selectedUserOb = userob;
			this.editUserPopupShown = true;
		},
		addNewUser() {
			let p = window.prompt('Please enter user email address');
			if(p.length > 0 && p.includes("@")) {
				let emval = p.toLowerCase().trim();
				let matches = this.store.users.find(item => item.email.toLowerCase() === emval);
				if(!matches) {
					this.selectedUserOb = {
						id: 0,
						email: emval,
					}
					this.editUserPopupShown = true;
				}
				else alert("Email address already has an account")
			}
			else {
				alert("Please enter a valid email address")
			}
		},
		closeEditUserPopup() {
			this.selectedUserOb = null;
			this.editUserPopupShown = false;
		},
	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		if(!this.store.initialObjectsFetched) this.getInitialObjects()
	},
}
</script>

<style scoped>
.headingtext {

}
.minwid {
	min-width: 150px;
}

@media screen and (max-width: 1100px) {
	.minwid {
		min-width: 100px;
	}
}
</style>