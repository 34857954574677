<template>
	<BlanketOverlayWithPopup v-if="userobin && userobin.id >= 0" title="User Editor" width="600" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="flex" style="justify-content: space-between; margin-top: 20px;">
				<div style="width: 75%;">
					<div class="form-input-group">
						<p class="form-headline">Email Address</p>
						<input type='email' v-model="userEditOb.email" placeholder="Email Address" />
						<div class="form-input-fail" :class="{shown : emailState === false}">Please enter a valid email address.</div>
					</div>
				</div>
				<div style="width: 20%">
					<div class="form-input-group">
						<p class="form-headline">Is Admin User</p>
						<input style="height: 18px;" type="checkbox" v-model="userEditOb.isAdmin" :true-value="1" :false-value="0" />
					</div>
				</div>
			</div>
			<div class="flex" style="justify-content: space-between;">
				<div class="half">
					<div class="form-input-group">
						<p class="form-headline">First Name</p>
						<input type='text' v-model="userEditOb.fname" placeholder="First Name" />
					</div>
				</div>
				<div class="half">
					<div class="form-input-group">
						<p class="form-headline">Last Name</p>
						<input type='text' v-model="userEditOb.lname" placeholder="Last Name" />
					</div>
				</div>
			</div>
			<div class="flex" style="justify-content: space-between;">
				<div class="half">
					<div class="form-input-group">
						<p class="form-headline">Market</p>
						<select v-model="userEditOb.marketId">
							<option value="0"></option>
							<option v-for="mk in store.markets" :key="mk.id" :value="mk.id">{{mk.name}}</option>
						</select>
					</div>
				</div>
				<div class="half">
					<div class="form-input-group">
						<p class="form-headline">Status</p>
						<select v-model="userEditOb.status">
							<option v-for="st in statuses" :key="st" :value="st">{{ucfirst(st)}}</option>
						</select>
					</div>
				</div>
			</div>
<!--			<div v-if="userEditOb.isAdmin === 0" class="">-->
<!--				<p class="form-headline">Station Permissions</p>-->
<!--				<div style="display: flex; justify-content: flex-start; gap: 5%;">-->
<!--					<div class="form-check-hold" v-for="st in store.stations" :key="st.id" style="width: 20%;">-->
<!--						<input style="height: 18px;" type="checkbox" class="form-check" v-model="userEditOb.stationPerms" :value="st.id" :id="'st'+st.id" />-->
<!--						<label class="form-check-label" :for="'st'+st.id">{{st.name}}</label>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button cancel-button" @click="closeCancel">Cancel</button>
			<button type="button" class="option-button" @click="saveUserInfo">Save Info</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "UserEditPopup",
	components: {
		BlanketOverlayWithPopup,
	},
	mixins: [globalMixin],
	props: {
		userobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			userEditOb: {
				id: null,
				email: '',
				isAdmin: 0,
				fname: '',
				lname: '',
				marketId: null,
				stationPerms: [],
				status: null,
			},
			statuses: ['active','disabled','deleted'],

		}
	},
	computed: {
		emailState() {
			if(this.userEditOb.email.length >= 0 && /.+@.+\..+/.test(this.userEditOb.email)) {
				return true;
			}
			return false;
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveUserInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true;
				this.isFetching = true;
				let targ = "/post/save-user-details.php";
				let myob = {};
				for(let pr in this.userEditOb) {
					myob[pr] = this.userEditOb[pr]
				}
				let sendob = {edituid: this.userEditOb.id, userob: myob};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data if needed
					if(ret.users) this.store.users = ret.users
					this.isFetching = false;
					this.store.showLoader = false;
					this.$emit('close');
				}.bind(this));
			}
		},
		populateContent() {
			if(this.userobin) {
				for(let pr in this.userobin) {
					this.userEditOb[pr] = this.userobin[pr];
				}
			}
			if((this.userobin === null || this.userobin.id === 0)) { //blank item
				let myemail = ''
				if(this.userobin.email && this.userobin.email.length > 0) myemail = this.userobin.email
				this.userEditOb = {
					id: 0,
					email: myemail,
					isAdmin: 0,
					fname: '',
					lname: '',
					marketId: null,
					stationPerms: [],
					status: 'active',
				}
			}
		},
	},
	watch: {
		userobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	}
}
</script>

<style scoped>

</style>