<template>
	<div>
		<LeftMenu></LeftMenu>
		<div class="wrapper" :class="{open : store.leftmenuhidden === false}">
			<div class="wrapper-inside">
				<div class="title-section">
					<h1>Asset Detail</h1>
				</div>

				<div v-if="asset" class="details-box">
					<div class="box-left">
						<h3>{{asset.name}}</h3>
						<div class="sub-heading">
							<p :class="'status status-'+asset.status" v-if="asset.clientNames.length > 0">{{asset.clientNames[0]}}</p>
							<p class="status status-available" v-else>Available</p>
						</div>
						<div class="description">
							{{asset.description}}
						</div>
					</div>
					<div class="box-right">
						<div class="detail-list">
							<div class="list-left">
								<div class="detail-item">
									<span class="item-heading">Status</span>
									<span :class="`item-value status-${asset.status.toLowerCase()}`">{{asset.status}}</span>
								</div>
								<div class="detail-item">
									<span class="item-heading">Type</span>
									<span class="item-value">{{getAssetTypeNameFromId(asset.assetTypeId)}}</span>
								</div>
								<div class="detail-item">
									<span class="item-heading">Availability</span>
									<span class="item-value"><TrackerWeekCell v-for="wk in asset.weeksCommencingBookings.slice(0,5)" :key="wk.dt" :wk="wk"></TrackerWeekCell></span>
								</div>
								<div class="detail-item">
									<span class="item-heading">Subtype</span>
									<span class="item-value">{{getAssetSubtypeNameFromId(asset.assetSubtypeId)}}</span>
								</div>
								<div class="detail-item">
									<span class="item-heading">Station</span>
									<span class="item-value">{{getStationNameFromId(asset.stationId)}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style="margin-top: 40px;">
					<p style="font-weight: bold; font-size: 22px;">Current & Future Bookings</p>
				</div>
				<div v-if="currentFutureBookings.length > 0" class="bookings">
					<BookingTileWidget v-for="item in currentFutureBookings" :key="item.id" :booking="item"></BookingTileWidget>
				</div>

				<div style="margin-top: 40px;">
					<p style="font-weight: bold; font-size: 22px;">Historical Bookings</p>
				</div>
				<div v-if="historicalBookings.length > 0" class="bookings">
					<BookingTileWidget v-for="item in historicalBookings" :key="item.id" :booking="item"></BookingTileWidget>
				</div>

			</div>
		</div>
		<BottomMobileMenu></BottomMobileMenu>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import LeftMenu from "@/components/LeftMenu";
import TrackerWeekCell from "@/components/TrackerWeekCell";
import BookingTileWidget from "@/components/BookingTileWidget"
import BottomMobileMenu from "@/components/BottomMobileMenu";

export default {
	name: "AssetDetailPage",
	components: {
		BottomMobileMenu,
		BookingTileWidget,
		TrackerWeekCell,
		LeftMenu
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			assetId: null,
		}
	},
	computed: {
		asset() {
			if (this.assetId) {
				return this.store.assetsExtended.find(item => item.id === this.assetId)
			}
			return null
		},
		todayString() {
			let d = new Date()
			return d.getFullYear() + "-" + this.ldzero(d.getMonth()+1) + "-" + this.ldzero(d.getDate())
		},
		currentFutureBookings() {
			if(this.asset) return this.asset.bookings.filter(item => item.enddate >= this.todayString)
			return []
		},
		historicalBookings() {
			if(this.asset) return this.asset.bookings.filter(item => item.enddate < this.todayString)
			return []
		}
	},
	methods: {},
	watch: {},
	mounted() {
		if (!this.store.initialObjectsFetched) this.getInitialObjects()
		let urlparams = this.$route.params;
		if (urlparams.assetId && parseInt(urlparams.assetId) > 0) {
			this.assetId = parseInt(urlparams.assetId)
		}
	},
	created() {

	},
}
</script>

<style scoped>
.status-sold {
	color: #DD0E66;
}
.status-available {
	color: #00B289;
}
.status-pending {
	color: #8D97A7;
}
.details-box {
	display: flex;
	border: 4px solid #F1F2F4;
}
.details-box .description {
	font-size: 16px;
	margin-top: 20px;
}
.details-box > div {
	padding: 50px 60px;
}
.details-box .box-left {
	width: 30%;
}
.details-box .box-left h3 {
	color: #1E3152;
	font-size: 26px;
	font-weight: 700;
}
.details-box .box-left .sub-heading {
	margin: 10px 0 0;
}
.details-box .box-left .sub-heading p.status {
	font-size: 26px;
	font-weight: 700;
}
.details-box .box-right {
	width: 70%;
	background-color: #F1F2F4;
}
.detail-list {
	display: flex;
}
.detail-list > div {
	width: 350px;
}
.detail-item {
	display: flex;
	font-size: 15px;
}
.detail-item:not(:last-child) {
	margin: 0 0 15px;
}
.detail-item .item-heading {
	width: 150px;
}
.detail-item .item-value {
	font-weight: 700;
}
.bookings {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	margin: 40px 0 0;
}

@media screen and (max-width: 1000px) {
	.details-box {
		flex-wrap: wrap;
	}
	.details-box .box-left,
	.details-box .box-right {
		width: 100%;
		padding: 30px;
	}
	.detail-list > div {
		width: 100%;
	}
	.detail-item .item-heading {
		width: 100px;
	}
}


</style>