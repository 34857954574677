<template>
	<div>

        <AssetEditPopup v-if="editAssetPopupShown" :assetobin="selectedAssetOb" v-on:close="closeEditAssetPopup"/>

		<LeftMenu></LeftMenu>
		<div class="wrapper" :class="{open : store.leftmenuhidden === false}">
			<div class="wrapper-inside">
				<div class="title-section" style="justify-content: flex-start; align-items: center; margin-bottom: 30px;">
					<h1 style="display: inline-block; margin-right: 50px;">Assets</h1>
					<div style="display: flex; align-items: center; justify-content: flex-start;">
						<button @click="addNewAsset" style="margin-left: 15px;" type="button" class="option-button">Add New <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg></button>
					</div>
				</div>

				<GlobalFilter></GlobalFilter>

				<div class="altable-holder" style="margin-top: 30px;">
					<div class="altbldiv noborderv">
						<div class="thead">
							<div class="trow">
								<div class="tcell headingtext minwid">Name</div>
								<div class="tcell headingtext minwid no-mobile">Type / Subtype</div>
								<div class="tcell headingtext statuswid no-mobile">Station</div>
								<div class="tcell headingtext statuswid no-mobile">Status</div>
								<div class="tcell headingtext minwid no-mobile">Client</div>
								<div class="tcell headingtext availwid">Availability</div>
								<div v-if="store.user.isAdmin === 1" class="tcell headingtext fnwid"></div>
							</div>
						</div>
						<div class="tbody">
							<div v-for="rowitem in rowsForPage" class="trow" :key="rowitem.id" style="cursor: pointer;" @click="viewAssetDetail(rowitem.id)" :title="rowitem.description">
								<div class="tcell minwid">
									<div>{{rowitem.name}}</div>
									<div class="mobile smaller">
										<p>{{getAssetTypeNameFromId(rowitem.assetTypeId)}} | {{getAssetSubtypeNameFromId(rowitem.assetSubtypeId)}} | {{getStationNameFromId(rowitem.stationId)}}</p>
									</div>
								</div>
								<div class="tcell minwid no-mobile">{{getAssetTypeNameFromId(rowitem.assetTypeId)}}
									<p v-if="rowitem.assetSubtypeId > 0">{{getAssetSubtypeNameFromId(rowitem.assetSubtypeId)}}</p>
								</div>
								<div class="tcell statuswid no-mobile">{{getStationNameFromId(rowitem.stationId)}}</div>
								<div class="tcell statuswid no-mobile">{{rowitem.status}}</div>
								<div class="tcell minwid no-mobile">
									<div v-if="rowitem.clientNames.length > 0">
										<span v-for="cl in rowitem.clientNames.slice(0,2)" :key="cl" style="display: block;">{{cl}}</span>
									</div>
								</div>
								<div class="tcell availwid">
									<TrackerWeekCell v-for="wk in rowitem.weeksCommencingBookings.slice(0,5)" :key="wk.dt" :wk="wk"></TrackerWeekCell>
								</div>
								<div v-if="store.user.isAdmin === 1"  class="tcell fnwid">
									<span class="action-icon" title="Edit" @click.stop="editAsset(rowitem)"><i class="mdi mdi-pencil"></i></span>
								</div>
							</div>
						</div>
					</div>

					<div style="margin: 30px 0; display: flex; align-items: center;">
						<ALTablePaginator :paginationpage="paginationPage" :paginatedrowscount="paginatedRows.length"
								v-on:nextpage="nextpage" v-on:prevpage="prevpage" v-on:setpage="paginationPage = $event"
						></ALTablePaginator>
					</div>

				</div>
			</div>
		</div>
		<BottomMobileMenu></BottomMobileMenu>
	</div>
    <router-view/>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import LeftMenu from "@/components/LeftMenu";
import ALTablePaginator from "@/components/ALTablePaginator";
import TrackerWeekCell from "@/components/TrackerWeekCell";
import AssetEditPopup from "@/components/AssetEditPopup";
import GlobalFilter from "@/components/GlobalFilter";
import BottomMobileMenu from "@/components/BottomMobileMenu";

export default {
	name: "AssetsPage",
	components: {
		BottomMobileMenu,
		GlobalFilter,
        AssetEditPopup,
		TrackerWeekCell,
		ALTablePaginator,
		LeftMenu
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			// table stuff
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			//end table stuff

            editAssetPopupShown: false,
            selectedAssetOb: {},
		}
	},
	computed: {
		//table stuff
		rows() {
            let rows = this.tblPrepareFilteredRows(this.store.assetsExtended, '', this.sortColName, this.sortColOrder)
            if( Object.keys(this.store.filters).length ) {
                for (let key in this.store.filters) {
                    // eslint-disable-next-line no-prototype-builtins
                    if( this.store.filters.hasOwnProperty(key) && this.store.filters[key].length ) {
                        rows = rows.filter((item) => {
                            if (key === 'clientNames' && item.clientNames.length && item.clientNames.some((element) => this.store.filters[key].includes(element) ) ) {
                                return item
                            } else if( key === 'rep_names' && item.bookings.length && item.bookings.every((booking) => this.store.filters[key].find( rep => rep.id === booking.repId )) ) {
                                return item
                            } else if( key !== 'text' ) {
                                // eslint-disable-next-line no-prototype-builtins
                                if (this.store.filters[key].includes(item[key])) {
                                    return item
                                }
                            } else {
                                if( item.name.toLowerCase().includes(this.store.filters[key].toLowerCase()) ) {
                                    return item
                                }
                            }
                        })
                    }
                }
            }

			return rows
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		viewAssetDetail(assid) {
			this.$router.push('/assets/'+assid)
		},
		editAsset(assetob) {
            this.selectedAssetOb = assetob;
            this.editAssetPopupShown = true;
		},
		closeEditAssetPopup() {
            this.selectedAssetOb = null;
            this.editAssetPopupShown = false;
        },
		addNewAsset() {
			this.selectedAssetOb = {
				id: 0,
				max_simultaneous: 1
			}
			this.editAssetPopupShown = true;
		},
	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		if(!this.store.initialObjectsFetched) this.getInitialObjects()
	},
}
</script>

<style scoped>
.altbldiv .tcell.availwid {
	width: 100px;
	flex-shrink: 0;
}
.trow .tcell:nth-of-type(2) {
	display: block;
}

@media screen and (max-width: 840px) {
	.trow .tcell:nth-of-type(1) {
		display: block;
	}
	.trow .tcell .smaller {
		font-size: 11px;
	}
	.trow .tcell:nth-of-type(2),
	.trow .tcell:nth-of-type(3) {
		display: none;
	}
}
</style>