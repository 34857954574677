<template>
    <div>
        <LeftMenu></LeftMenu>
        <div class="wrapper" :class="{open : store.leftmenuhidden === false}">
            <div class="wrapper-inside">
                <div class="title-section">
                    <h1>New Booking Form</h1>
                </div>
                <form @submit.prevent="form_submit">
					<div v-if="store.user.isAdmin === 1" class="form-input-group">
						<p class="form-headline">Rep</p>
						<select v-model="book_data.repId">
							<option v-for="u in store.users" :key="u.id" :selected="u.id === book_data.repId" :value="u.id">
								{{u.fname}} {{u.lname}}
							</option>
						</select>
					</div>
                    <div class="form-input-group">
                        <p class="form-headline">Station</p>
                        <FilterItem label="Select Station">
                            <template v-slot:items>
                                <div class="filter-input">
                                    <input class="filt-textinput" v-model="search.station" placeholder="Search" />
                                </div>
                                <div class="filter-item" v-for="(item, index) in stations" :key="index">
                                    <label><input v-model="book_data.stations" type="checkbox" :value="item" />{{item.name}}</label>
                                </div>
                            </template>
                        </FilterItem>
                        <div v-if="form_submitting && !has_station" class="form-input-fail">Station cannot be empty</div>
                    </div>
                    <div style="margin: 10px 0 20px 0; padding: 10px; width: calc(100% - 30px); border: 1px solid #CCC;">
                        <p v-if="book_data.stations.length === 0">Please select at least one station from the list above</p>
                        <div v-else>
                            <div v-for="a in book_data.stations" :key="a.id" class="asset-item">
                                {{a.name}}
                                <i class="mdi mdi-close" @click="removeStation(a.id)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-input-group">
                        <p class="form-headline">Assets</p>
                        <FilterItem label="Select Assets">
                            <template v-slot:items>
                                <div class="filter-input">
                                    <input class="filt-textinput" v-model="search.asset" placeholder="Search" />
                                </div>
                                <div class="filter-item" v-for="(item, index) in asset_list" :key="index">
                                    <label><input v-model="book_data.assetIds" type="checkbox" :value="item.id" />
                                        <span v-if="get_station_name( item.stationId )">{{ get_station_name( item.stationId ).name }}</span> - {{item.name}}
                                    </label>
                                </div>
                            </template>
                        </FilterItem>
                    </div>
                    <div style="margin: 10px 0 20px 0; padding: 10px; width: calc(100% - 30px); border: 1px solid #CCC;">
                        <p v-if="selectedAssetObs.length === 0">Please select at least one asset from the list above</p>
                        <div v-else>
                            <div v-for="a in selectedAssetObs" :key="a.id" class="asset-item">
                                <span v-if="get_station_name( a.stationId )">{{ get_station_name( a.stationId ).name }}</span> - {{a.name}}
                                <i class="mdi mdi-close" @click="removeAsset(a.id)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-input-group">
                        <p class="form-headline">Client Name</p>
                        <input v-model="book_data.clientName" />
                        <div v-if="form_submitting && !has_client_name" class="form-input-fail">Client Name cannot be empty</div>
                    </div>

                    <div class="date-fields">
                        <div class="date-field">
                            <div class="form-input-group">
                                <p class="form-headline">Start Date</p>
                                <date-picker
                                    id="start_date"
                                    class="dater-tr"
                                    v-model:value="book_data.startdate"
                                    valueType="format"
                                    format="YYYY-MM-DD"
                                    :disabled-date="notBeforeToday"
                                    @change="select_date"
                                />
                                <!--<div v-if="form_submitting && !has_start_date" class="form-input-fail">Please select start date</div>-->
                            </div>
                        </div>
                        <div class="date-field">
                            <div class="form-input-group">
                                <p class="form-headline">End Date</p>
                                <date-picker
                                    id="end_date"
                                    class="dater-tr"
                                    v-model:value="book_data.enddate"
                                    valueType="format"
                                    format="YYYY-MM-DD"
                                    :disabled-date="notBeforeStartDate"
                                    @change="select_date"
                                />
                                <!--
                                <div v-if="form_submitting && !has_end_date" class="form-input-fail">Please select end date</div>
                                <div v-if="form_submitting && !is_valid_end_date" class="form-input-fail">End date must be after start date</div>
                                -->
                            </div>
                        </div>
                    </div>

                    <div style="margin: 10px 0 20px 0; padding: 10px; width: calc(100% - 30px); border: 1px solid #CCC;">
                        <p v-if="book_data.dates.length === 0">Please select at least one start and end date from the list above</p>
                        <div v-else>
                            <div v-for="(d, index) in book_data.dates" :key="index" class="asset-item">
                                Start Date : {{d.start_date}} - End Date : {{ d.end_date }}
                                <i class="mdi mdi-close" @click="removeDate(index)"></i>
                            </div>
                        </div>
                    </div>

                    <div class="form-input-group">
                        <div class="form-headline">
                            <input type="checkbox" style="display: inline; width: unset; margin-right: 5px;" v-model="book_data.waitingOnCredits" />
                            <span>Tick this box if you are still waiting on credit lines</span>
                        </div>
                    </div>

                    <div class="form-input-group">
                        <p class="form-headline">Campaign Copy / Credit Lines</p>
                        <textarea v-model="book_data.campaignCopy" rows="6"></textarea>
                    </div>

                    <div class="form-input-group">
                        <p class="form-headline">Attach supporting document (max 5Mb, PDF or XLSX only)</p>
                        <p style="font-size: 12px; color: #666; margin-bottom: 10px;">To attach more than one document, larger than 5Mb or of different file type, please share a Google Drive or Dropbox link in the Additional Information field below.</p>
                        <UploadFile @file-changed="file_changed" />
                        <div v-if="form_submitting && !is_valid_file_type" class="form-input-fail">Not a valid file type</div>
                    </div>

                    <div class="form-input-group">
                        <p class="form-headline">Additional Information</p>
                        <textarea v-model="book_data.additionalInfo" rows="6"></textarea>
                    </div>

                    <div class="form-input-group">
                        <button type="submit" class="option-button">
                            <span v-if="!form_submitting">Submit</span>
                            <span v-else>Working...</span>
                        </button>
                    </div>

                </form>
            </div>
        </div>
        <BottomMobileMenu></BottomMobileMenu>
    </div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import LeftMenu from "@/components/LeftMenu";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import axios from "axios";
import UploadFile from "@/components/UploadFile";
import BottomMobileMenu from "@/components/BottomMobileMenu";
import FilterItem from "@/components/FilterItem";

export default {
    name: "NewBookingFormPage",
    components: {
        BottomMobileMenu,
        UploadFile,
        LeftMenu,
        DatePicker,
        FilterItem
    },
    props: {},
    mixins: [globalMixin],
    data: function () {
        return {
            store: store,
            form_submitting: false,
            book_data: {
                stations: [],
                assetIds: [],
                clientName: "",
                startdate: null,
                enddate: null,
                dates: [],
                campaignCopy: "",
                supportingDocs: "",
                additionalInfo: "",
                repId: "",
                waitingOnCredits: false,
            },
            search: {
                station: null,
                asset: null
            },
            model_assetSelection: null,
        }
    },
    computed: {
        u() {
            return this.store.user
        },
        has_station() {
            return this.book_data.stations.length
        },
        has_client_name() {
            return this.book_data.clientName !== ""
        },
		has_asset() {
			return this.book_data.assetIds.length
		},
        has_booking_dates() {
            return this.book_data.dates.length
        },
        has_start_date() {
            return this.book_data.startdate !== null
        },
        has_end_date() {
            return this.book_data.enddate !== null
        },
        is_valid_end_date() {
            return this.has_start_date && (this.book_data.enddate > this.book_data.startdate)
        },
        is_valid_file_type() {
            let allowed_file_types = ['png','pdf','jpg','xlsx']
            if( this.book_data.supportingDocs ) {
                let ext = this.book_data.supportingDocs.name.split('.').pop().toLowerCase()
                if( !allowed_file_types.includes(ext) ) {
                    return false
                }
            }
            return true
        },
        has_errors() {
            let errors = [
                !this.has_station,
                !this.has_client_name,
                !this.has_booking_dates,
                !this.is_valid_file_type,
				!this.has_asset,
            ]
            return errors.includes(true)
        },
        selectedAssetObs() {
            return this.store.assets.filter(item => this.book_data.assetIds.includes(item.id))
        },
        asset_list() {
            let assets = store.assets.filter((item) => {
                if( this.book_data.stations.some(station => parseInt( station.id ) === parseInt( item.stationId )) )
                    return item
            })

            if( this.search.asset )
                assets = assets.filter(item => item.name.toLowerCase().includes(this.search.asset.toLowerCase()))

            return assets
        },
        stations() {
            let stations = this.store.stations

            if( this.search.station )
                stations = stations.filter(item => item.name.toLowerCase().includes(this.search.station.toLowerCase()))

            return stations
        }
    },
    methods: {
        addAsset(assetid) {
            if(assetid && parseInt(assetid) > 0 && !this.book_data.assetIds.includes(assetid)) {
                this.book_data.assetIds.push(parseInt(assetid))
                this.model_assetSelection = null
            }
        },
        removeAsset(assetid) {
            if(this.book_data.assetIds.includes(assetid)) {
                let ind = this.book_data.assetIds.indexOf(assetid)
                this.book_data.assetIds.splice(ind, 1)
            }
        },
        removeStation( station_id ) {
            if(this.book_data.stations.some(item => item.id === station_id)) {
                let ind = this.book_data.stations.findIndex(item => parseInt( item.id ) === station_id)
                this.book_data.stations.splice(ind, 1)
            }

        },
        removeDate( index ) {
            this.book_data.dates.splice(index, 1)
        },
        notBeforeToday(date) {
            return date <= new Date(new Date().setHours(0, 0, 0, 0));
        },
        notBeforeStartDate(date) {
            return this.has_start_date && date <= new Date( this.book_data.startdate )
        },
        file_changed( value ) {
            this.book_data.supportingDocs = value
        },
        form_submit() {
            if (this.form_submitting === false) {
                if (this.has_errors) {
                    alert("Please check the form for errors")
                    return false
                }
                this.form_submitting = true
                let target = "/post/save-booking-submission.php",
                    form_data = new FormData()
                form_data.append('uid', this.store.user.id)
                form_data.append('kevsesstoken', this.store.kevsesstoken)
                for (let key in this.book_data) {
                    let value = this.book_data[key]

                    if( key === 'stations' || key === 'dates' )
                        value = JSON.stringify( value )

                    form_data.append(key, value)
                }
                axios({
                    url: target,
                    method: 'POST',
                    data: form_data
                }, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status === 200) {
                        let data = response.data
                        if (data.error) {
                            this.showKalertError(data, true)
                        }
                        else {
                            if (data.bookings) this.store.bookings = data.bookings
                            if (data.booking_parents) this.store.booking_parents = data.booking_parents
                            this.form_submitting = false
                            this.$router.push('/booking-parents')
                            this.showKalertSuccess({message: 'Submitted successfully!'}, true)
                        }
                    }
                })
            }
        },
        select_station( event ) {
            let value = JSON.parse( event.target.value )

            if( !this.book_data.stations.some(station => station.id === value.id) )
                this.book_data.stations.push( value )
        },
        select_date() {
            if( this.book_data.startdate && this.book_data.enddate ) {
                this.book_data.dates.push({
                    start_date: this.book_data.startdate,
                    end_date: this.book_data.enddate
                })
                this.book_data.startdate = null
                this.book_data.enddate = null
            }
        },
        get_station_name( id ) {
            let station = this.store.stations.find(item => item.id === id)

            if( station )
                return station

            return null
        }
    },
    watch: {
        u() {
            this.book_data.repId = this.u.id
        },
        model_assetSelection() {
            if(this.model_assetSelection) this.addAsset(this.model_assetSelection)
        },
    },
    mounted() {
		this.book_data.repId = store.user.id
    },
    created() {
        if(!this.store.initialObjectsFetched) this.getInitialObjects()
    },
}
</script>

<style scoped>
form {
    max-width: 700px;
    width: 100%;
}
label {
    display: block;
}
.mx-datepicker {
    width: 100%;
}
select,
option {
    text-transform: capitalize;
}
.form-input-fail {
    color: #F00;
    font-size: 12px;
    text-align: left;
    display: block;
    margin: 10px 0 0;
}
.asset-item {
    padding: 5px;
    margin: 3px 0;
    border: 1px solid #CCC;
    background-color: #FAFAFA;
}
.asset-item .mdi {
    cursor: pointer;
}
.asset-item .mdi:hover {
    color: var(--red);
}
.date-fields {
    display: flex;
    justify-content: space-between;
}

.date-field {
    width: calc(50% - 10px);
}


.filter-container {
    position: relative;
    width: 100%;
}

.filter-input input {
    color: #1E3152;
    font-size: 14px;
    width: calc(100% - 20px);
    background-color: #fff;
    border: 1px solid #8F97A6;
    padding: 3px 8px;
}

.filter-item {
    padding: 2px 12px;
    cursor: pointer;
    display: flex;
}
.filter-item label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.filter-item input {
    display: inline-block;
    width: unset;
    margin: 5px 5px 5px 0;
}
.filter-item * {
    cursor: pointer;
}
.selected-item {
    margin-top: 5px;
    margin-right: 5px;
    padding: 5px;
    border: 1px solid #CCC;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
}
.selected-item i {
    cursor: pointer;
    margin-left: 5px;
}
.selected-item i:hover {
    color: #F00;
}
</style>