<template>
    <div class="filter-container">
        <div class="filter-placeholder" @click="toggle_items">
            <span>{{label}}</span>
            <i v-if="!show_items" class="mdi mdi-chevron-down"></i>
            <i v-if="show_items" class="mdi mdi-chevron-up"></i>
        </div>
        <div v-if="show_items" class="filter-list">
			<slot name="items"></slot>
        </div>
        <div class="filter-selections">
			<slot name="selecteds"></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: "FilterItem",
    props: {
        label: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            show_items: false,
		}
    },
    computed: {

    },
    methods: {
        toggle_items() {
            this.show_items = !this.show_items
        },
        remove_item( item ) {
            let index = this.selected.findIndex((s) => s === item)
            this.selected.splice(index, 1)
        }
    }
}
</script>

<style scoped>
/*.filter-container {*/
/*    position: relative;*/
/*    width: 180px;*/
/*}*/
.filter-placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f2f6;
    border: 1px solid #ccc;
    padding: 7px 12px;
    cursor: pointer;
}
.filter-placeholder i {
    font-size: 18px;
    color: #888;
    margin-left: 10px;
}
.filter-list {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: 5px 0;
    z-index: 1;
}

</style>
