<template>
	<div class="filter-wrapper">
        <div class="filter-container">
            <input v-model="store.filters.text" placeholder="Keyword" style="height: 26px;">
        </div>

		<FilterItem label="Station">
			<template v-slot:items>
				<div class="filter-item" v-for="(item, index) in store.stations" :key="index">
					<label><input v-model="store.filters.stationId" type="checkbox" :value="item.id" />{{item.name}}</label>
				</div>
			</template>
			<template v-slot:selecteds>
				<span class="selected-item" v-for="s in store.filters.stationId" :key="s">
					{{getStationNameFromId(s)}} <i class="mdi mdi-close" @click="remove_item('stations', s)"></i>
				</span>
			</template>
		</FilterItem>

		<FilterItem label="Types">
			<template v-slot:items>
				<div class="filter-item" v-for="(item, index) in store.assetTypes" :key="index">
					<label><input v-model="store.filters.assetTypeId" type="checkbox" :value="item.id" />{{item.name}}</label>
				</div>
			</template>
			<template v-slot:selecteds>
				<span class="selected-item" v-for="s in store.filters.assetTypeId" :key="s">
					{{getAssetTypeNameFromId(s)}} <i class="mdi mdi-close" @click="remove_item('types', s)"></i>
				</span>
			</template>
		</FilterItem>

		<FilterItem label="Sub Types">
			<template v-slot:items>
				<div class="filter-item" v-for="(item, index) in store.assetSubtypes" :key="index">
					<label><input v-model="store.filters.assetSubtypeId" type="checkbox" :value="item.id" />{{item.name}}</label>
				</div>
			</template>
			<template v-slot:selecteds>
				<span class="selected-item" v-for="s in store.filters.assetSubtypeId" :key="s">
					{{getAssetSubtypeNameFromId(s)}} <i class="mdi mdi-close" @click="remove_item('subtypes', s)"></i>
				</span>
			</template>
		</FilterItem>

        <FilterItem label="Rep Names">
            <template v-slot:items>
				<input class="filt-textinput" v-model="repSearchInput" placeholder="Search" />
                <div class="filter-item" v-for="(item, index) in filteredReps" :key="index">
                    <label><input v-model="store.filters.rep_names" type="checkbox" :value="item" />{{item.fname}} {{item.lname}}</label>
                </div>
            </template>
            <template v-slot:selecteds>
				<span class="selected-item" v-for="s in store.filters.rep_names" :key="s">
					{{ s.fname }} {{ s.lname }} <i class="mdi mdi-close" @click="remove_item('reps', s)"></i>
				</span>
            </template>
        </FilterItem>

        <FilterItem label="Client Names">
            <template v-slot:items>
				<input class="filt-textinput" v-model="clientSearchInput" placeholder="Search" />
                <div class="filter-item" v-for="(item, index) in filteredClients" :key="index">
                    <label><input v-model="store.filters.clientNames" type="checkbox" :value="item" />{{item}}</label>
                </div>
            </template>
            <template v-slot:selecteds>
				<span class="selected-item" v-for="s in store.filters.clientNames" :key="s">
					{{ s }} <i class="mdi mdi-close" @click="remove_item('clients', s)"></i>
				</span>
            </template>
        </FilterItem>

		<FilterItem label="Status">
			<template v-slot:items>
				<div class="filter-item" v-for="(item, index) in status_items" :key="index">
					<label><input v-model="store.filters.status" type="checkbox" :value="item" />{{item}}</label>
				</div>
			</template>
			<template v-slot:selecteds>
				<span class="selected-item" v-for="s in store.filters.status" :key="s.id">
					{{s}} <i class="mdi mdi-close" @click="remove_item('status', s)"></i>
				</span>
			</template>
		</FilterItem>

	</div>
</template>

<script>
import {store} from "@/store";
import FilterItem from "@/components/FilterItem";
import {globalMixin} from "@/mixins";

export default {
	name: "GlobalFilter",
	components: {FilterItem},
	props: [],
	mixins: [globalMixin],
	data: function () {
		return {
            store: store,
			status_items: ['Available', 'Pending', 'Sold'],

			repSearchInput: '',
			clientSearchInput: '',
		}
	},
	computed: {
		filteredReps() {
			let sch = this.repSearchInput.toLowerCase()
			let f = this.store.users
			if(sch.length > 0) {
				f = this.store.users.filter(item => item.fname.toLowerCase().includes(sch) || item.lname.toLowerCase().includes(sch) || item.email.toLowerCase().includes(sch))
			}
			return f.slice(0, 20) //just return the first 20 results
		},
        client_names() {
            let assets = this.store.assetsExtended,
                clients = []
            for (let asset_index in assets) {
                let asset_clients = assets[asset_index].clientNames
                for ( let client_index in asset_clients ) {
                    if( !clients.includes( asset_clients[client_index] ) ) {
                        clients.push( asset_clients[client_index] )
                    }
                }
            }
            return clients
        },
		filteredClients() {
			let sch = this.clientSearchInput.toLowerCase()
			let f = this.client_names
			if(sch.length > 0) {
				f = this.client_names.filter(item => item.toLowerCase().includes(sch))
			}
			return f.slice(0, 20) //just return the first 20 results
		},
    },
	methods: {
		remove_item(ftype, item) {
			let arr = []
			if(ftype === 'status') arr = this.store.filters.status
			else if(ftype === 'subtypes') arr = this.store.filters.assetSubtypeId
			else if(ftype === 'types') arr = this.store.filters.assetTypeId
			else if(ftype === 'stations') arr = this.store.filters.stationId
			else if(ftype === 'reps') arr = this.store.filters.rep_names
			else if(ftype === 'clients') arr = this.store.filters.clientNames
			if(arr.length > 0) {
				let index = arr.findIndex((s) => s === item)
				arr.splice(index, 1)
			}
		}
    },
    created() {

    },
	mounted() {

	},
    watch: {

    }
}
</script>

<style scoped>
.filter-wrapper {
    display: flex;
    justify-content: space-around;
}
.filter-wrapper > *:not(:last-child) {
    margin-right: 10px;
}
.filter-container {
    position: relative;
    width: calc(20% - 20px);
}
input {
    color: #1E3152;
    font-size: 14px;
    width: calc(100% - 20px);
    background-color: #fff;
    border: 1px solid #8F97A6;
    padding: 3px 8px;
}

.filter-item {
	padding: 2px 12px;
	cursor: pointer;
	display: flex;
}
.filter-item label {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.filter-item input {
	display: inline-block;
	width: unset;
	margin: 5px 5px 5px 0;
}
.filter-item * {
	cursor: pointer;
}
.selected-item {
	margin-top: 5px;
	margin-right: 5px;
	padding: 5px;
	border: 1px solid #CCC;
	display: inline-flex;
	align-items: center;
	border-radius: 5px;
}
.selected-item i {
	cursor: pointer;
	margin-left: 5px;
}
.selected-item i:hover {
	color: #F00;
}

@media screen and (max-width: 900px) {
	.filter-wrapper {
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.filter-container {
		position: relative;
		width: 44%;
		margin-bottom: 20px;
	}
}
</style>